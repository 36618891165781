import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Avatar from '@mui/material/Avatar';
import ButtonBase from '@mui/material/ButtonBase';
import Link from '@mui/material/Link';
import ListItemIcon from '@mui/material/ListItemIcon';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Badge from '@mui/material/Badge';
import ListItemText from '@mui/material/ListItemText';
import LogOutIcon from 'undercurrent/icons/lucide/LogOut';
import { selectors as appConfigSelectors } from 'modules/appConfig';
import { hasAuthToken as getHasAuthToken } from 'modules/auth';
import { actions as openIdActions } from 'modules/openid';
import useContactList from 'modules/queries/contact/useList';
import { useAccountDetailsWithEverything } from 'modules/queries/account/useDetails';
import HookedLink from '../common/HookedLink';
import useLeftHandLayout from '../context';

export function TopNavUser() {
	const [menuElement, setMenuElement] = useState();
	const open = menuElement !== undefined;
	const hasAuthToken = useSelector(getHasAuthToken);
	const { data: contactListData } = useContactList(
		{},
		{ enabled: hasAuthToken },
	);
	const defaultContact = contactListData?.items.find(
		(contact) => contact.type === 'main',
	);
	const nameInitial = (defaultContact?.fname ||
		defaultContact?.lname ||
		'?')[0];

	const { data: accountDetails } = useAccountDetailsWithEverything(undefined, {
		refetchInterval: 30000,
	});
	const highlights = useMemo(() => {
		if (!accountDetails?.highlights) return {};
		const result = {};

		for (const { count, key } of accountDetails.highlights) {
			result[key] = Number(count) || 0;
		}
		return result;
	}, [accountDetails]);

	const dispatch = useDispatch();

	const { appPath } = useLeftHandLayout();

	// Close menu when appPath changes
	useEffect(() => {
		if (appPath === undefined) return;
		setMenuElement(undefined);
	}, [appPath]);

	const userProfilePath = useSelector(appConfigSelectors.getUserProfilePath);
	const logout = () => dispatch(openIdActions.logout());

	const hasSecureNotes = highlights['secure-notes'] > 0;
	const showBadge = hasSecureNotes;

	return (
		<>
			<ButtonBase
				onClick={({ currentTarget }) => setMenuElement(currentTarget)}
				sx={{ borderRadius: (theme) => theme.borderRadius.roundedFull }}
			>
				<Badge
					color={showBadge ? 'error' : undefined}
					overlap="circular"
					variant="dot"
				>
					<Avatar>{nameInitial}</Avatar>
				</Badge>
			</ButtonBase>
			<Menu
				open={open}
				anchorEl={menuElement}
				anchorOrigin={{
					vertical: 'bottom',
					horizontal: 'right',
				}}
				transformOrigin={{
					vertical: 'top',
					horizontal: 'right',
				}}
				onClose={() => setMenuElement()}
			>
				<MenuItem component={HookedLink} href="/account" role="menuitem">
					<ListItemText primary="Account Overview" />
				</MenuItem>
				<MenuItem
					component={Link}
					href={userProfilePath}
					target="_blank"
					role="menuitem"
				>
					<ListItemText primary="My user profile" />
				</MenuItem>
				<MenuItem component={HookedLink} href="/account/users" role="menuitem">
					<ListItemText primary="Users" />
				</MenuItem>
				<MenuItem
					component={HookedLink}
					href="/account/history"
					role="menuitem"
				>
					<ListItemText primary="History" />
				</MenuItem>
				<MenuItem
					component={HookedLink}
					href="/account/secure-notes"
					role="menuitem"
				>
					<ListItemText>Secure notes</ListItemText>
					{hasSecureNotes && <Badge color="error" variant="dot" />}
				</MenuItem>
				<MenuItem component={Link} href="/account/policies" role="menuitem">
					<ListItemText primary="Policies" />
				</MenuItem>
				<MenuItem onClick={logout} color="danger">
					<ListItemIcon>
						<LogOutIcon fontSize="small" />
					</ListItemIcon>
					<ListItemText primary="Logout" />
				</MenuItem>
			</Menu>
		</>
	);
}

export default TopNavUser;
