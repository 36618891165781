import React, { useMemo, useState } from 'react';

import Box from '@material-ui/core/Box';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import TextField from '@material-ui/core/TextField';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';

export const useTaskScheduler = () => {
	const initDeferUntil = dayjs().add(1, 'hour').format('YYYY-MM-DDTHH:mm');
	const [deferUntil, setDeferUntil] = useState(initDeferUntil);
	const [mode, setMode] = useState('immediate');

	const deferUntilDayjs = useMemo(() => dayjs(deferUntil), [deferUntil]);

	const deferIsInThePast =
		deferUntilDayjs.isBefore(dayjs()) &&
		"We can't schedule a reboot in the past";
	const deferIsTooFarInTheFuture =
		deferUntilDayjs.isAfter(dayjs().add(7, 'day')) &&
		'Please choose a date and time within the next 7 days';

	const isValidDeferTime =
		mode === 'immediate' ||
		(deferUntilDayjs.isValid() &&
			!deferIsInThePast &&
			!deferIsTooFarInTheFuture);

	const resetDeferUntil = () => {
		setDeferUntil(initDeferUntil);
	};

	const deferError = deferIsInThePast || deferIsTooFarInTheFuture;

	return {
		deferError,
		deferUntil,
		deferUntilDayjs,
		isValidDeferTime,
		mode,
		resetDeferUntil,
		setDeferUntil,
		setMode,
	};
};

const modes = {
	immediate: 'immediate',
	deferred: 'deferred',
};

/**
 * @param {object} props
 * @param {string} props.datetimeValue in format 'YYYY-MM-DDTHH:mm'
 * @param {(date: string) => void} props.onDatetimeChange datetime in format 'YYYY-MM-DDTHH:mm'
 * @param {'immediate' | 'deferred'} props.mode
 * @param {(mode: 'immediate' | 'deferred') => void} props.onModeChange
 * @param {string} props.dateTimeError
 */
const TaskScheduler = ({
	datetimeValue,
	onDatetimeChange,
	mode,
	onModeChange,
	dateTimeError,
	...otherProps
}) => {
	return (
		<div {...otherProps}>
			<FormControl component="fieldset">
				<RadioGroup
					aria-label="when"
					name="when"
					value={mode}
					onChange={(event) => onModeChange(event.target.value)}
				>
					<FormControlLabel
						value={modes.immediate}
						control={<Radio color="primary" />}
						label="Now"
					/>
					<FormControlLabel
						value={modes.deferred}
						control={<Radio color="primary" />}
						label="Schedule for Later"
					/>
				</RadioGroup>
			</FormControl>

			{mode === modes.deferred && (
				<Box mt={1}>
					<TextField
						variant="outlined"
						type="datetime-local"
						value={datetimeValue}
						onChange={(event) => onDatetimeChange(event.target.value)}
						label="Date / Time"
						InputLabelProps={{
							shrink: true,
						}}
						inputProps={{
							min: dayjs().format('YYYY-MM-DDTHH:mm'),
							max: dayjs().add(7, 'day').format('YYYY-MM-DDTHH:mm'),
						}}
						error={Boolean(dateTimeError)}
						helperText={dateTimeError}
					/>
				</Box>
			)}
		</div>
	);
};

TaskScheduler.propTypes = {
	onDatetimeChange: PropTypes.func.isRequired,
	onModeChange: PropTypes.func.isRequired,
};

export { modes };

export default TaskScheduler;
