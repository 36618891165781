// @ts-check
import { useQuery } from '@tanstack/react-query';
import api from 'modules/queries/api';

/**
 * @param {import('club-sauce/public/account/index.raw').LWApiPublicAccountDetailsParamsRawI} params
 * @param {object} options
 * @param {import('@tanstack/react-query').UseQueryOptions['enabled']} [options.enabled]
 */
const useAccountDetails = (params, { enabled } = {}) =>
	useQuery({
		queryKey: ['/account/details', params],
		queryFn: () => api.account.apiRaw.details(params),
		enabled,
	});

/**
 * @param {import('club-sauce/public/account/index.raw').LWApiPublicAccountDetailsParamsRawI} params
 * @param {import('@tanstack/react-query').UseQueryOptions} options
 */
export const useAccountDetailsWithEverything = (params, options) =>
	useAccountDetails(
		{
			...params,
			alsowith: [
				...(typeof params?.alsowith === 'string'
					? [params?.alsowith]
					: params?.alsowith || []),
				'businessUnit',
				'customerLifecycle',
				'highlights',
				'managementPortal',
				'referAFriend',
			],
		},
		options,
	);

export default useAccountDetails;
