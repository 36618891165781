// @ts-check

import React from 'react';
import { useSelector } from 'react-redux';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { formatCurrency } from 'banana-stand/format';
import { hasAuthToken as getHasAuthToken } from 'modules/auth';
import useBillingBalance from 'modules/queries/billing/useBalance';
import useAccountDetails from 'modules/queries/account/useDetails';
import FooterMenu from './FooterMenu';

/**
 * @param {React.ComponentProps<typeof Stack>} props
 */
export function LeftNavFooter(props) {
	const hasAuthToken = useSelector(getHasAuthToken);
	const { data: billingBalanceData } = useBillingBalance({
		enabled: hasAuthToken,
	});
	const { data: accountUserDetailsData } = useAccountDetails({});

	const accountNumber = accountUserDetailsData?.accnt;
	const balanceDue = formatCurrency(billingBalanceData?.accountBalanceDisplay);

	return (
		<Stack
			flexDirection="row"
			justifyContent="center"
			alignItems="center"
			padding={200}
			border={(theme) => `1px solid ${theme.palette.uc.border.main}`}
			borderRadius={(theme) => theme.borderRadius.roundedSm}
			gap={50}
			useFlexGap
			{...props}
		>
			<Stack
				flex="0% 1 1"
				flexDirection="column"
				justifyContent="center"
				alignItems="flex-start"
				gap={100}
				useFlexGap
			>
				<Box>
					<Typography variant="label-sm">
						Account Balance <strong>{balanceDue} Due</strong>
					</Typography>
				</Box>
				<Box>
					<Typography variant="label-xs">
						Account ID: {accountNumber}
					</Typography>
				</Box>
			</Stack>
			<FooterMenu />
		</Stack>
	);
}

export default LeftNavFooter;
