import { createSelector } from 'reselect';
import { selectors as dynamicChildSelectors } from 'modules/api/account/limits/dynamicChildModule';
import { selectors as optionsSelectors } from 'modules/server/resize/options';
import { selectors as assetDetailsSelectors } from 'modules/api/asset/detailsModule';
import prodConfigDeployOntoSelectors from 'modules/basket/productConfig/deployOntoSelectors';

const isLoading = createSelector(
	assetDetailsSelectors.isLoading,
	dynamicChildSelectors.isLoading,
	optionsSelectors.isLoading,
	(asset, dynamic, options) => asset || dynamic || options,
);

const getConfigChildLimits = createSelector(
	dynamicChildSelectors.getNativeData,
	prodConfigDeployOntoSelectors.getDynamicChildType,
	(dynamicChildData, productConfigChildType) => {
		return dynamicChildData?.[productConfigChildType];
	},
);

const getResizeChildLimits = createSelector(
	dynamicChildSelectors.getNativeData,
	assetDetailsSelectors.childType,
	(dynamicChildData, assetDetailsChildType) => {
		return dynamicChildData?.[assetDetailsChildType];
	},
);

const getCreateChildInitialCpu = createSelector(
	getConfigChildLimits,
	(limits) => limits?.Cpu || 1,
);

const getCloneInitialCpu = createSelector(
	assetDetailsSelectors.CPUint,
	(current) => current || 1,
);

const getCreateChildInitialRam = createSelector(
	getConfigChildLimits,
	optionsSelectors.selectedPrivateParent,
	(limits, selectedPrivateParent) => {
		const freeMemory = selectedPrivateParent?.resources?.memory?.free;
		const memoryLimit = limits?.Memory;
		return Math.min(freeMemory, memoryLimit);
	},
);

const getCloneInitialRam = createSelector(
	assetDetailsSelectors.RAM,
	(current) => current || 1,
);

const getCreateChildInitialDisk = createSelector(
	getConfigChildLimits,
	optionsSelectors.selectedPrivateParent,
	(limits, selectedPrivateParent) => {
		const freeDisk = selectedPrivateParent?.resources?.diskspace?.free;
		const diskLimit = limits?.Disk;
		return Math.min(freeDisk, diskLimit);
	},
);

const getCloneInitialDisk = createSelector(
	assetDetailsSelectors.storageSize,
	(current) => current || 1,
);

const getResizeMaxDisk = createSelector(
	optionsSelectors.selectedPrivateParent,
	assetDetailsSelectors.storageSize,
	(selectedPrivateParent, storageSize) => {
		return (
			(selectedPrivateParent?.resources?.diskspace?.free ?? 0) + storageSize
		);
	},
);

const getConfigMaxDisk = createSelector(
	optionsSelectors.selectedPrivateParent,
	(selectedPrivateParent) => selectedPrivateParent?.resources?.diskspace?.free,
);

const getResizeMaxRam = createSelector(
	optionsSelectors.selectedPrivateParent,
	assetDetailsSelectors.RAM,
	(selectedPrivateParent, RAM) => {
		return (selectedPrivateParent?.resources?.memory?.free ?? 0) + RAM;
	},
);

const getConfigMaxRam = createSelector(
	optionsSelectors.selectedPrivateParent,
	(selectedPrivateParent) => selectedPrivateParent?.resources?.memory?.free,
);

const getResizeSliderinitialValues = createSelector(
	assetDetailsSelectors.CPUint,
	assetDetailsSelectors.RAM,
	assetDetailsSelectors.storageSize,
	(currentCpu, currentRam, currentStorage) => ({
		cpu: currentCpu,
		ram: currentRam,
		disk: currentStorage,
	}),
);

const getResizeSliderProps = createSelector(
	getResizeMaxRam,
	getResizeMaxDisk,
	assetDetailsSelectors.CPU,
	assetDetailsSelectors.RAMdisplay,
	assetDetailsSelectors.storageSize,
	assetDetailsSelectors.storageSizeDisplay,
	getResizeChildLimits,
	optionsSelectors.selectedPrivateParent,
	assetDetailsSelectors.getPreventResizeDown,
	(
		maxRam,
		maxDisk,
		currentCpuDisplay,
		currentRamDisplay,
		currentStorage,
		currentStorageDisplay,
		dynamicLimits,
		selectedParent,
		preventResizeDown,
	) => {
		return {
			cpu: {
				min: dynamicLimits?.Cpu,
				max: selectedParent?.vcpu,
				current: currentCpuDisplay,
			},
			ram: {
				min: dynamicLimits?.Memory,
				max: maxRam,
				current: currentRamDisplay,
			},
			disk: {
				min: preventResizeDown ? currentStorage : dynamicLimits?.Disk,
				max: maxDisk,
				current: currentStorageDisplay,
			},
		};
	},
);

const getConfigSliderProps = createSelector(
	getConfigMaxRam,
	getConfigMaxDisk,
	assetDetailsSelectors.CPU,
	assetDetailsSelectors.RAMdisplay,
	assetDetailsSelectors.storageSize,
	assetDetailsSelectors.storageSizeDisplay,
	getConfigChildLimits,
	optionsSelectors.selectedPrivateParent,
	assetDetailsSelectors.getPreventResizeDown,
	(
		maxRam,
		maxDisk,
		currentCpuDisplay,
		currentRamDisplay,
		currentStorage,
		currentStorageDisplay,
		dynamicLimits,
		selectedParent,
		preventResizeDown,
	) => {
		return {
			cpu: {
				min: dynamicLimits?.Cpu,
				max: selectedParent?.vcpu,
				current: currentCpuDisplay,
			},
			ram: {
				min: dynamicLimits?.Memory,
				max: maxRam,
				current: currentRamDisplay,
			},
			disk: {
				min: preventResizeDown ? currentStorage : dynamicLimits?.Disk,
				max: maxDisk,
				current: currentStorageDisplay,
			},
		};
	},
);

const getParentRegion = createSelector(
	optionsSelectors.selectedPrivateParent,
	(selectedParent) => selectedParent?.region_id,
);

const privateParentSelectors = {
	getResizeChildLimits,
	getConfigChildLimits,
	getParentRegion,
	getCreateChildInitialCpu,
	getCloneInitialCpu,
	getCreateChildInitialDisk,
	getCloneInitialDisk,
	getCreateChildInitialRam,
	getCloneInitialRam,
	getResizeSliderinitialValues,
	getResizeSliderProps,
	getConfigSliderProps,
	getResizeMaxDisk,
	getConfigMaxDisk,
	getResizeMaxRam,
	getConfigMaxRam,
	isLoading,
};

export { privateParentSelectors as selectors };
export default privateParentSelectors;
