import React from 'react';
import Stack from '@mui/material/Stack';
import { useNavLeftSize } from '../navSetter';
import LeftNavHeader from './Header';
import LeftNavList from './List';
import LeftNavFooter from './Footer';

const LEFTNAV_SIZE_PX = 247;

export function LeftNav() {
	useNavLeftSize(LEFTNAV_SIZE_PX);

	return (
		<Stack
			component="nav"
			overflow="hidden"
			position="fixed"
			left={0}
			top={0}
			bottom={0}
			width={LEFTNAV_SIZE_PX}
			direction="column"
			alignItems="stretch"
			zIndex={(theme) => theme.zIndex.appBar}
		>
			<LeftNavHeader />
			<LeftNavList flex="0% 1 1" overflow="auto" />
			<LeftNavFooter margin={200} />
		</Stack>
	);
}

export default LeftNav;
