import { takeEvery, call, put, take } from 'redux-saga/effects';
import { actions as vncConsoleActions } from 'modules/api/server/vncConsoleModule';
import snackbarSaga from 'modules/snackbar/sagas/sagas';
import { replace } from 'connected-react-router';
import consoleActions from './actions';

function* openConsole({ uniqId } = {}) {
	const argArray = (() => {
		return [
			`/external/vnc-console/${uniqId}`,
			`console-${uniqId}`,
			'width=1024,height=940',
		];
	})();
	yield call([window, 'open'], ...argArray);
}

function* initVncConsole({ uniqId }) {
	yield put(vncConsoleActions.clear()); // TODO: put a comment explaining why we do this.
	yield put(vncConsoleActions.fetch({ uniq_id: uniqId }));
	const res = yield take([
		vncConsoleActions.setType,
		vncConsoleActions.errorType,
	]);
	if (res?.payload?.url) {
		yield put(replace(res.payload.url));
	} else
		yield call(snackbarSaga, {
			error: true,
			errorMessage:
				'Something when wrong. Please contact support or try again.',
		});
}

export default function* consoleSagas() {
	yield takeEvery(consoleActions.SERVER_OPEN_CONSOLE, openConsole);
	yield takeEvery(consoleActions.SERVER_INIT_VNC_CONSOLE, initVncConsole);
}
