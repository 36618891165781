import { useNavTitle, useNavBreadcrumbs, useNavActivePath } from './navSetter';

/**
 * @typedef NavStatePropsI
 * @property {import('./context').LeftHandLayoutContentI["title"]?} title
 * @property {import('./context').LeftHandLayoutContentI["breadcrumbs"]?} breadcrumbs
 * @property {import('./context').LeftHandLayoutContentI["path"]?} path
 */

/**
 * Set the nav states
 * @param {NavStatePropsI} props
 */
export function NavState(props) {
	const { title = '', breadcrumbs = [], path = undefined } = props;
	useNavTitle(title);
	useNavBreadcrumbs(breadcrumbs);
	useNavActivePath(path);

	return null;
}

export default NavState;
