import {
	call,
	takeLatest,
	put,
	select,
	take,
	takeEvery,
} from 'redux-saga/effects';

import { actions as assetListActions } from 'modules/api/asset/listModule';
import basketActions from 'modules/basket/actions';
import { selectors as routeSelectors } from 'modules/route';
import {
	isSetupUser as getIsSetupUser,
	isMasquerade as getIsMasquerade,
	username as usernameSelector,
} from 'modules/auth';
import { cartItemAddAdditionalSagas } from 'modules/api/market/cart/item/addModule';
import {
	cartItemModifyAdditionalSagas,
	cartItemModifySelectors,
} from 'modules/api/market/cart/item/modifyModule';
import {
	cartItemRemoveAdditionalSagas,
	cartItemRemoveSelectors,
} from 'modules/api/market/cart/item/removeModule';
import { cartDiscountAddAdditionalSagas } from 'modules/api/market/cart/discount/addModule';
import { cartDiscountRemoveAdditionalSagas } from 'modules/api/market/cart/discount/removeModule';
import { cartMetadataAdditionalSagas } from 'modules/api/market/cart/metadataModule';
import { getErrorStringHelper } from 'utility/redux/selectorHelperFunctions/apiModules';
import snackbarSaga from 'modules/snackbar/sagas/sagas';
import { setCookie } from 'utility/tools/cookies';
import { CART_ITEM_COUNT_COOKIE } from 'utility/constants/baskets';
import {
	cartDetailsActions,
	cartDetailsAdditionalSagas,
	cartDetailsSelectors,
} from 'modules/api/market/cart/detailsModule';
import { claimBasket } from 'modules/basket/sagas/claim';
import { hasPaymentMethodCheck } from 'modules/payment/paymentSagas';
import { cartOrderAdditionalSagas } from 'modules/api/market/cart/orderModule';
import { cartDiscountModifyAdditionalSagas } from 'modules/api/market/cart/discount/modifyModule';
import { cartExpireAdditionalSagas } from 'modules/api/market/cart/expireModule';
import {
	gaSendAttributionData,
	waitForGaReady,
} from 'modules/attribution/sagas';
import { createCart as createCartSaga } from 'modules/cart/sagas';
import routeActions from 'modules/route/actions';
import contentKeys from 'components/common/Snackbar/contents/contentKeys';
import storeUuid from 'modules/basket/sagas/storeUuid';
import {
	removeUndefinedKeys,
	convertEmptyObjectToUndef,
} from 'utility/tools/objects';
import { AttributionData } from 'modules/attribution';
import { additionalSagas as cartAdminQuoteCreateAdditionalSagas } from 'modules/api/market/cart/admin/quote/createModule';
import { cartDomainAdditionalSagas } from 'modules/api/market/cart/domainModule';
import analyticsActions from 'modules/gtm/actions';
import { cartDiscountBulkAdditionalSagas } from 'modules/api/market/cart/discount/bulkModule';
import cartActions from './actions';
import cartSelectors from './selectors';
import mergeOldCartWithNew from './mergeOldCartWithNew';
import assertMetadataSaga from './assertMetadataSaga';

function* initCartStepper() {
	const isSetupUser = yield select(getIsSetupUser);
	if (!isSetupUser) yield put(assetListActions.init());

	const pathName = yield select(routeSelectors.getPathName);
	switch (pathName) {
		case '/shop/special-offers': {
			const alreadyVisited = yield select(
				cartDetailsSelectors.getSpecialOffersVisited,
			);
			if (!alreadyVisited)
				yield put(
					cartActions.assertMetadata({
						metadata: { specialOffersVisited: true },
					}),
				);
			break;
		}
		case '/account/create': {
			const alreadyVisited = yield select(
				cartDetailsSelectors.getAccountDetailsVisited,
			);
			if (!alreadyVisited)
				yield put(
					cartActions.assertMetadata({
						metadata: { accountDetailsVisited: true },
					}),
				);
			break;
		}
		default: // '/shop/checkout' and '/shop/payment-method' to nothing here.
	}
}

export function* invalidateCart() {
	const uuid = yield select(cartDetailsSelectors.getUuid);
	yield put(cartDetailsActions.invalidate({ uuid }));
}

function* maybeInvalidateCart() {
	const removeIsLoading = yield select(cartItemRemoveSelectors.isLoading);
	const modifyIsLoading = yield select(cartItemModifySelectors.isLoading);

	// since race conditions can result from updating multiple items at
	// once, invalidate the cart data to be certain that cart state is up to date
	if (!removeIsLoading && !modifyIsLoading) {
		yield call(invalidateCart);
	}
}

/**
 * @param {object} param0
 * @param {import('club-sauce/public/market/cart/item.raw').LWApiPublicMarketCartItemAddParamsRawI['alsowith']} [param0.alsowith]
 * @param {import('club-sauce/public/market/cart/item.raw').LWApiPublicMarketCartItemAddParamsRawI['cycle']} param0.cycle
 * @param {import('club-sauce/public/market/cart/item.raw').LWApiPublicMarketCartItemAddParamsRawI['item']} param0.item
 * @param {string} [param0.cartUuid] the cart to add the item to
 * @returns {{error: object, newCart: import('club-sauce/public/market/cart/index.raw').LWApiPublicMarketCartDetailsResultRawI}}
 * */
export function* addToCartSaga({
	alsowith: alsowithArg,
	cycle,
	item,
	cartUuid: cartUuidArg,
}) {
	const cartUuid = yield select(cartDetailsSelectors.getUuid);

	const { data: newCartPortion, error } = yield call(
		cartItemAddAdditionalSagas.fetchDirectly,
		{
			alsowith: alsowithArg || ['discounts', 'items', 'totals'],
			cycle,
			item,
			uuid: cartUuidArg || cartUuid,
		},
	);

	if (error) {
		yield call(snackbarSaga, {
			error: true,
			errorMessage: getErrorStringHelper(error),
		});
		return { error };
	}

	const newCart = yield call(mergeOldCartWithNew, { newCartPortion });

	return { newCart };
}

/**
 * @param {object} param0
 * @param {import('club-sauce/public/market/cart/item.raw').LWApiPublicMarketCartItemAddParamsRawI['alsowith']} param0.alsowith
 * @param {string} param0.cartUuid the cart the item is in
 * @param {string} param0.itemUuid the item to be removed
 * @returns {{error: object, newCart: import('club-sauce/public/market/cart/index.raw').LWApiPublicMarketCartDetailsResultRawI}}
 * */
export function* cartItemRemoveSaga({
	alsowith: alsowithArg,
	cartUuid: cartUuidArg,
	itemUuid,
	onError,
}) {
	const cartUuid = yield select(cartDetailsSelectors.getUuid);
	const itemToRemove = (yield select(cartDetailsSelectors.getItemsByUuid))[
		itemUuid
	];
	const itemToRemoveTotals = (yield select(
		cartDetailsSelectors.getTotalsByItemUuid,
	))[itemUuid];

	const { data: newCartPortion, error } = yield call(
		cartItemRemoveAdditionalSagas.fetchDirectly,
		{
			alsowith: alsowithArg || ['discounts', 'items', 'totals'],
			uuid: cartUuidArg || cartUuid,
			item_uuid: itemUuid,
		},
	);

	if (error) {
		onError();
		yield call(snackbarSaga, {
			error: true,
			errorMessage: getErrorStringHelper(error),
		});
		return { error };
	}

	// send data to gtm once item has been successfully removed
	yield put(
		cartActions.itemRemoved({
			item: itemToRemove,
			itemTotals: itemToRemoveTotals,
		}),
	);

	const newCart = yield call(mergeOldCartWithNew, { newCartPortion });

	yield call(maybeInvalidateCart);

	return { newCart };
}

/**
 * @param {object} param0
 * @param {import('club-sauce/public/market/cart/item.raw').LWApiPublicMarketCartItemAddParamsRawI['alsowith']} param0.alsowith
 * @param {import('club-sauce/public/market/cart/item.raw').LWApiPublicMarketCartItemAddParamsRawI['cycle']} param0.cycle
 * @param {import('club-sauce/public/market/cart/item.raw').LWApiPublicMarketCartItemAddParamsRawI['item']} param0.item
 * @param {string} param0.cartUuid the cart the item is in
 * @param {string} param0.itemUuid the item being modified
 * @param {number} [param0.units]
 * @returns {{error: object, newCart: import('club-sauce/public/market/cart/index.raw').LWApiPublicMarketCartDetailsResultRawI}}
 * */
export function* modifyItemInCart({
	alsowith: alsowithArg,
	cycle,
	item,
	cartUuid: cartUuidArg,
	itemUuid,
	units,
	...cartItemModifyOptions
}) {
	const cartUuid = yield select(cartDetailsSelectors.getUuid);

	const { data: newCartPortion, error } = yield call(
		cartItemModifyAdditionalSagas.fetchDirectly,
		{
			alsowith: alsowithArg || ['discounts', 'items', 'totals'],
			cycle,
			item,
			uuid: cartUuidArg || cartUuid,
			item_uuid: itemUuid,
			units,
		},
		undefined,
		cartItemModifyOptions,
	);

	if (error) {
		yield call(snackbarSaga, {
			error: true,
			errorMessage: getErrorStringHelper(error),
		});
		return { error };
	}

	const newCart = yield call(mergeOldCartWithNew, { newCartPortion });

	return { newCart };
}

/**
 * @param {object} param0
 * @param {string} param0.itemUuid
 * @param {number} param0.units
 */
function* updateItemUnitsSaga({
	itemUuid,
	units: newUnits,
	...cartItemModifyOptions
}) {
	const prevUnits = Number(
		(yield select(cartDetailsSelectors.getItemsByUuid))[itemUuid].units,
	);

	const { newCart, error } = yield call(modifyItemInCart, {
		itemUuid,
		units: newUnits,
		setAllRes: true,
		...cartItemModifyOptions,
	});

	if (error) {
		return;
	}

	yield call(maybeInvalidateCart);

	const newItem = (yield select(cartDetailsSelectors.getItemsByUuid))[itemUuid];
	const newItemTotals = (yield select(
		cartDetailsSelectors.getTotalsByItemUuid,
	))[itemUuid];

	if (prevUnits < newUnits) {
		yield put(
			analyticsActions.sendAnalyticsCartItemUnitsAdded({
				units: newUnits - prevUnits,
				item: newItem,
				itemTotals: newItemTotals,
				cart: newCart,
			}),
		);
	}

	if (prevUnits > newUnits) {
		yield put(
			analyticsActions.sendAnalyticsCartItemUnitsRemoved({
				units: prevUnits - newUnits,
				item: newItem,
				itemTotals: newItemTotals,
				cart: newCart,
			}),
		);
	}
}

export function* waitForCartInit() {
	const cartIsInitialized = yield select(cartSelectors.getCartIsInitialized);

	if (!cartIsInitialized) {
		yield take(cartActions.CART_INITIALIZED);
	}
}

function* handlePatchItemProperties({ properties, uuid }) {
	const prevItem = (yield select(cartDetailsSelectors.getItemsByUuid))[uuid];
	const { code, cycle, details } = prevItem;
	const options = details.options.map(
		// eslint-disable-next-line camelcase
		({ num_units, parent_ptok_id, parent_ptov_id, ptok_id, ptov_id }) => ({
			num_units,
			parent_ptok_id,
			parent_ptov_id,
			ptok_id,
			ptov_id,
		}),
	);

	yield call(modifyItemInCart, {
		cycle,
		item: {
			code,
			properties: { ...details.properties, ...properties },
			options,
			region_id: details.region_id,
		},
		itemUuid: uuid,
	});
}

function* modifyHostnameSaga({
	domain,
	subdomain,
	entityUuid,
	cartUuid: cartUuidArg,
	alsowith: alsowithArg,
}) {
	const cartUuid = cartUuidArg || (yield select(cartDetailsSelectors.getUuid));

	const { data, error } = yield call(cartDomainAdditionalSagas.fetchDirectly, {
		uuid: cartUuid,
		domain,
		subdomain,
		entity_uuid: entityUuid,
		alsowith: alsowithArg || ['items', 'metadata'],
	});

	if (error) {
		yield call(snackbarSaga, {
			errorMessage: getErrorStringHelper(error),
			error: true,
		});
		return;
	}

	yield call(mergeOldCartWithNew, { newCartPortion: data });
}

function* editCartDetailsSaga({ description, domainToAutoAdd, name, onError }) {
	const cartUuid = yield select(cartDetailsSelectors.getUuid);

	yield call(assertMetadataSaga, {
		metadata: { name, description },
		uuid: cartUuid,
		onError,
	});

	yield call(modifyHostnameSaga, { domain: domainToAutoAdd, cartUuid });
}

function* handleApplyAdditionalInstructions({ additionalInstructions }) {
	const { error } = yield call(assertMetadataSaga, {
		metadata: { additionalInstructions },
	});

	if (error) {
		yield call(snackbarSaga, {
			errorMessage: getErrorStringHelper(error),
			error: true,
		});
		return;
	}

	yield call(snackbarSaga, {
		successMessage: 'Additional Instructions saved.',
	});
}

function* setCartItemCookies() {
	const cartItemCount = yield select(cartDetailsSelectors.getTotalUnits);

	setCookie({
		name: CART_ITEM_COUNT_COOKIE,
		value: cartItemCount,
	});
}

function* handleSubmitOrder() {
	yield call(claimBasket); // We need this here because the basket may not yet have been approved when claim was called on init.
	if (!(yield call(hasPaymentMethodCheck, { navigate: true }))) return; // will show a snackbar if payment method is missing.
	const uuid = yield select(cartDetailsSelectors.getUuid);

	const { data, error } = yield call(cartOrderAdditionalSagas.fetchDirectly, {
		alsowith: ['metadata', 'items', 'totals'],
		uuid,
	});

	if (error) {
		yield call(snackbarSaga, {
			errorMessage: getErrorStringHelper(error),
			error: true,
		});

		yield put(cartActions.resetIsOrderProcessing());

		return;
	}

	yield call(gaSendAttributionData, {
		...data,
		metadata: {
			...data.metadata,
			order_id: data.metadata.order_id,
		},
	});

	yield call(createCartSaga);

	yield put(cartActions.resetIsOrderProcessing());

	yield put(routeActions.smartNavigate({ path: '/shop/complete' }));
}

function* handleAddCoupon({ code, ...rest }) {
	const cartUuid = yield select(cartDetailsSelectors.getUuid);

	const { data } = yield call(
		cartDiscountAddAdditionalSagas.fetchDirectly,
		{
			alsowith: ['discounts', 'items', 'totals'],
			discount: { type: 'coupon', code },
			uuid: cartUuid,
		},
		undefined,
		{ ...rest },
	);

	yield call(mergeOldCartWithNew, { newCartPortion: data });
}

function* handleBulkDiscount({ amount, repetitions, ...rest }) {
	const cartUuid = yield select(cartDetailsSelectors.getUuid);

	const { data } = yield call(
		cartDiscountBulkAdditionalSagas.fetchDirectly,
		{
			alsowith: ['discounts', 'items', 'totals'],
			auto_apply: 1,
			discount: { type: 'admin', amount, repetitions },
			uuid: cartUuid,
		},
		undefined,
		{ ...rest },
	);

	yield call(mergeOldCartWithNew, { newCartPortion: data });
}

function* handleResetBulkDiscount({ type, ...fetchDirectlyOptions }) {
	const cartUuid = yield select(cartDetailsSelectors.getUuid);

	const { data } = yield call(
		cartDiscountBulkAdditionalSagas.fetchDirectly,
		{
			alsowith: ['discounts', 'items', 'totals'],
			uuid: cartUuid,
			// by not including a `discount` param, the api bulk removes all admin discounts
		},
		undefined,
		fetchDirectlyOptions,
	);

	yield call(mergeOldCartWithNew, { newCartPortion: data });
}

function* handleRemoveDiscount({ uuid: discountUuid, ...rest }) {
	const cartUuid = yield select(cartDetailsSelectors.getUuid);

	const { data } = yield call(
		cartDiscountRemoveAdditionalSagas.fetchDirectly,
		{
			alsowith: ['discounts', 'items', 'totals'],
			discount_uuid: discountUuid,
			uuid: cartUuid,
		},
		undefined,
		{ ...rest },
	);

	yield call(mergeOldCartWithNew, { newCartPortion: data });
}

/**
 * @param {MarketCartProductItem['details']} productItemDetails
 */
const createProductItemParam = (productItemDetails) => {
	const itemParam = {
		code: productItemDetails.code,
		options: productItemDetails.options.map((option) => ({
			num_units: option.num_units,
			parent_ptok_id: option.parent_ptok_id,
			parent_ptov_id: option.parent_ptov_id,
			ptok_id: option.ptok_id,
			ptov_id: option.ptov_id,
		})),
		package_to_product_id: productItemDetails.package_to_product_id,
		properties: productItemDetails.properties,
		region_id: productItemDetails.region_id,
	};

	return itemParam;
};

/**
 * @param {MarketCartItem} cartItem
 */
const createItemParam = (cartItem) => {
	if (cartItem.type === 'product') {
		return createProductItemParam(cartItem.details);
	}

	if (cartItem.type === 'package') {
		return {
			code: cartItem.code,
			products: cartItem.details.products.map((productItem) =>
				createProductItemParam(productItem),
			),
		};
	}

	return {};
};

/**
 * @param {object} param0
 * @param {import('club-sauce/public/market/cart/item.raw').LWApiPublicMarketCartItemAddParamsRawI['alsowith']} param0.alsowith
 * @param {string} param0.cartUuid the cart the item is in
 * @param {string} param0.itemUuid the item being duplicated
 * */
function* handleDuplicateItem({
	alsowith: alsowithArg,
	cartUuid: cartUuidArg,
	itemUuid,
}) {
	const cartUuid = yield select(cartDetailsSelectors.getUuid) || cartUuidArg;
	const item = (yield select(cartDetailsSelectors.getItemsByUuid))[itemUuid];

	if (!item) {
		return;
	}

	const itemParam = createItemParam(item);
	const { error } = yield call(addToCartSaga, {
		cartUuid,
		alsowith: alsowithArg,
		cycle: item.cycle,
		item: itemParam,
	});

	if (!error) {
		yield call(snackbarSaga, {
			successContentKey: contentKeys.StyledTextEm,
			successContentProps: {
				emText: item.details.title,
				beforeText: 'Added another',
				afterText: 'to your cart.',
				emTextProps: {
					bold: true,
					variant: 'body2',
				},
				textProps: { variant: 'body2' },
			},
		});
	}
}

function* handleAdminUpdateItem({
	customerNotes,
	itemUuid,
	discountAmount,
	discountRepetitions,
	onFinally = () => {},
	onError,
}) {
	const cartUuid = yield select(cartDetailsSelectors.getUuid);
	const allItemCustomerNotes = (yield select(
		cartDetailsSelectors.getNativeData,
	)).metadata.customerNotes;
	const { uuid: discountUuid } =
		(yield select(cartDetailsSelectors.getDiscounts))?.find(
			(discount) =>
				discount.item_uuid === itemUuid && discount.type === 'admin',
		) || {};
	const prevCustomerNotes = allItemCustomerNotes?.[itemUuid] || '';

	// need to make these cart calls one at a time or else the backend can get
	// tied up with conflicting cart versions and will return an error
	if (customerNotes !== prevCustomerNotes) {
		yield call(
			cartMetadataAdditionalSagas.fetchDirectly,
			{
				alsowith: [],
				data: {
					customerNotes: { ...allItemCustomerNotes, [itemUuid]: customerNotes },
				},
				uuid: cartUuid,
			},
			undefined,
			{ onError },
		);
	}

	if (discountUuid) {
		const { data } = yield call(
			cartDiscountModifyAdditionalSagas.fetchDirectly,
			{
				alsowith: ['discounts', 'items', 'totals', 'metadata'],
				discount: {
					type: 'admin',
					amount: discountAmount,
					repetitions: discountRepetitions,
					uuid: discountUuid,
				},
				uuid: cartUuid,
			},
			undefined,
			{ onError },
		);

		yield call(mergeOldCartWithNew, { newCartPortion: data });
	} else {
		const { data } = yield call(
			cartDiscountAddAdditionalSagas.fetchDirectly,
			{
				alsowith: ['discounts', 'items', 'totals', 'metadata'],
				discount: {
					type: 'admin',
					amount: discountAmount,
					repetitions: discountRepetitions,
					item_uuid: itemUuid,
				},
				uuid: cartUuid,
			},
			undefined,
			{ onError },
		);

		yield call(mergeOldCartWithNew, { newCartPortion: data });
	}

	onFinally();
}

function* handleAdminSetExpire({ date }) {
	const cartUuid = yield select(cartDetailsSelectors.getUuid);

	const { data, error } = yield call(cartExpireAdditionalSagas.fetchDirectly, {
		date,
		uuid: cartUuid,
	});

	if (error) {
		yield call(snackbarSaga, {
			error: true,
			errorMessage: getErrorStringHelper(error),
		});

		return;
	}

	yield call(mergeOldCartWithNew, { newCartPortion: data });
}

export function* createMetadata() {
	yield call(waitForGaReady);
	const isMasquerade = yield select(getIsMasquerade);
	const attributionData = new AttributionData();
	let metadata = { ...(isMasquerade ? {} : attributionData.all) };

	metadata = removeUndefinedKeys(metadata);
	metadata = convertEmptyObjectToUndef(metadata);
	return metadata;
}

/**
 * Calls correct api to create new basket. If an `oppId` is provided, creates quote. Otherwise creates basic basket. This could someday support quickcarts and templates. If the need arises, do that.
 * @param {String} oppId
 * @returns data or error response from api
 */
export function* fetchCreateCart(oppId) {
	const username = yield select(usernameSelector);

	if (oppId) {
		// quotes are the only basket types that get oppIds.
		const { data, error } = yield call(
			cartAdminQuoteCreateAdditionalSagas.fetchDirectly,
			{
				opportunity_id: oppId,
				basket_admin_username: username,
				alsowith: ['admin', 'discounts', 'items', 'metadata', 'totals'],
			},
		);

		if (!error) {
			yield put(cartDetailsActions.override(data));
		}

		return { data, error };
	}

	const { data, error } = yield call(cartDetailsAdditionalSagas.fetchDirectly, {
		alsowith: ['admin', 'discounts', 'items', 'metadata', 'totals'],
	});

	return { data, error };
}

/**
 * Creates new cart, stores uuid of new basket in local storage, and asserts metadata to new basket.
 * @param {String} oppId
 * @returns {String | null} uuid of newly created basket. Null if fetch action was noop or response was error
 */
export function* createCart(oppId) {
	const { data, error } = yield call(fetchCreateCart, oppId);

	if (error || !data) {
		if (error) {
			yield call(snackbarSaga, {
				error,
				errorMessage: error?.data?.full_message,
			});
		}
		return null;
	}

	// destructure the uuid from either payload and place in local storage.
	const { uuid } = data;

	yield call(storeUuid, { uuid });

	yield put(basketActions.created());
	const metadata = yield call(createMetadata);
	if (metadata)
		yield call(assertMetadataSaga, {
			metadata,
		});

	return uuid;
}

export default function* cartSagas() {
	yield takeLatest([cartActions.CART_STEPPER_INIT], initCartStepper);

	yield takeLatest(
		[cartActions.CART_PATCH_ITEM_PROPERTIES],
		handlePatchItemProperties,
	);

	yield takeLatest(
		cartActions.CART_APPLY_ADDITIONAL_INSTRUCTIONS,
		handleApplyAdditionalInstructions,
	);

	yield takeLatest(
		// set cookies anytime cartDetails state is updated
		[cartDetailsActions.setType, cartDetailsActions.overrideType],
		setCartItemCookies,
	);

	yield takeLatest(cartActions.CART_SUBMIT_ORDER, handleSubmitOrder);

	yield takeEvery([cartActions.CART_REMOVE_ITEM], cartItemRemoveSaga);

	yield takeLatest([cartActions.CART_ADD_COUPON], handleAddCoupon);

	yield takeLatest([cartActions.CART_BULK_DISCOUNT], handleBulkDiscount);

	yield takeLatest(
		[cartActions.CART_RESET_BULK_DISCOUNT],
		handleResetBulkDiscount,
	);

	yield takeLatest([cartActions.CART_REMOVE_DISCOUNT], handleRemoveDiscount);

	yield takeLatest(cartActions.CART_ASSERT_METADATA, assertMetadataSaga);

	yield takeEvery(cartActions.CART_DUPLICATE_ITEM, handleDuplicateItem);

	yield takeEvery(cartActions.CART_ADMIN_UPDATE_ITEM, handleAdminUpdateItem);

	yield takeEvery(cartActions.CART_ADMIN_SET_EXPIRE, handleAdminSetExpire);

	yield takeEvery(cartActions.CART_MODIFY_HOSTNAME, modifyHostnameSaga);

	yield takeEvery(cartActions.CART_UPDATE_ITEM_UNITS, updateItemUnitsSaga);

	yield takeEvery(cartActions.CART_EDIT_DETAILS, editCartDetailsSaga);
}
