import React, { useEffect, useMemo } from 'react';
import styled from 'styled-components';
import { useParams } from 'react-router-dom';
import Box from '@material-ui/core/Box';
import CheckedIcon from '@material-ui/icons/CheckCircle';
import usePackageConfigContext from 'banana-stand/packageConfig/usePackageConfigContext';
import applyDiscount from 'banana-stand/parsers/applyDiscount';
import LWTypography from 'components/common/LWTypography';
import packageConfigActions from 'modules/basket/packageConfig/actions';
import { useDispatch, useSelector } from 'react-redux';
import packageConfigSelectors from 'modules/basket/packageConfig/selectors';
import { formatCurrency } from 'banana-stand/format';
import { cartDetailsSelectors } from 'modules/api/market/cart/detailsModule';
import PropTypes from 'prop-types';
import ProductOptionTile from 'components/atoms/ProductOptionTile';
import { maybeRound } from 'components/cart/Item/CartItemDiscounts';
import useBillingCycleList from 'modules/queries/billing/cycle/useList';

const SBillingCycleOption = styled(ProductOptionTile)`
	.duration {
		position: relative;
	}

	.checked-icon {
		position: absolute;
		transform: translateX(-115%);
	}
`;

/** @param {string | undefined} friendlyDisplay */
export const createDurationDisplay = (friendlyDisplay) => {
	if (!friendlyDisplay) return '';
	return `Billed every ${friendlyDisplay}`;
};

/**
 * @param {object} props
 * @param {string} props.displayName
 * @param {number} props.discount
 * @param {string} props.id
 * @param {boolean} props.hidePricing
 * */
const BillingCycleOption = ({ displayName, discount, id, hidePricing }) => {
	const dispatch = useDispatch();

	const { totalConfiguredPrice: pricePerMonth } = usePackageConfigContext();
	const priceAfterDiscount = applyDiscount(pricePerMonth, discount);
	const { selectedBillingCycleId } = useSelector(
		packageConfigSelectors.getStateSlice,
	);
	const isSelected = id === selectedBillingCycleId;

	const handleSelect = () => {
		if (!isSelected) {
			dispatch(packageConfigActions.setBillingCycle({ cycleId: id }));
		}
	};

	return (
		<SBillingCycleOption onClick={handleSelect} $isSelected={isSelected}>
			<LWTypography className="duration" semiBold>
				{isSelected && <CheckedIcon className="checked-icon" color="primary" />}
				{displayName}
			</LWTypography>
			{!hidePricing && (
				<Box data-testid="BillingCycleOption__Price">
					<LWTypography variant="h3" BoxProps={{ mb: 1 }}>
						{formatCurrency(priceAfterDiscount)}
					</LWTypography>
					<LWTypography variant="body2">per month</LWTypography>
				</Box>
			)}
			{!hidePricing && Boolean(discount) && (
				<LWTypography data-testid="BillingCycleOption__Discount">
					Billed upfront.{' '}
					<LWTypography inline semiBold>
						Save {maybeRound(discount * 100)}%
					</LWTypography>
				</LWTypography>
			)}
		</SBillingCycleOption>
	);
};

const SBillingCycles = styled.div`
	display: grid;
	gap: ${({ theme }) => theme.spacing(2)}px;
	grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
`;

const BillingCycleSelect = ({ BoxProps = {}, testId }) => {
	const dispatch = useDispatch();
	const { uuid } = useParams();
	const { billingCycles, defaultBillingCycle } = usePackageConfigContext();
	const { data: billingCycleListData } = useBillingCycleList();

	const defaultBillingCycleId = defaultBillingCycle?.id;
	const cartItems = useSelector(cartDetailsSelectors.getNativeItems);
	const initSelectedBillingCycle = useMemo(
		() => cartItems.find((item) => item.uuid === uuid)?.cycle,
		[cartItems, uuid],
	);

	const initSelectedBillingCycleId = useMemo(
		() =>
			billingCycles.find(({ cycle }) => cycle === initSelectedBillingCycle)?.id,
		[billingCycles, initSelectedBillingCycle],
	);

	useEffect(() => {
		if (initSelectedBillingCycleId) {
			dispatch(
				packageConfigActions.setBillingCycle({
					cycleId: initSelectedBillingCycleId,
				}),
			);
		} else if (defaultBillingCycleId) {
			dispatch(
				packageConfigActions.setBillingCycle({
					cycleId: defaultBillingCycleId,
				}),
			);
		}
	}, [defaultBillingCycleId, dispatch, initSelectedBillingCycleId]);

	if (
		!billingCycles?.length ||
		billingCycles?.length <= 1 ||
		!billingCycleListData
	) {
		return null;
	}

	const hasDiscount = () =>
		billingCycles.some(({ discount }) => Boolean(discount));

	const cyclesData = billingCycleListData.cycles || {};

	return (
		<Box data-testid={testId} {...BoxProps}>
			<LWTypography semiBold BoxProps={{ mb: 2 }}>
				Select Subscription
			</LWTypography>
			<SBillingCycles>
				{billingCycles.map((cycle) => (
					<BillingCycleOption
						key={cycle.id}
						hidePricing={!hasDiscount()}
						displayName={createDurationDisplay(
							cyclesData[cycle.cycle]?.display_name,
						)}
						{...cycle}
					/>
				))}
			</SBillingCycles>
		</Box>
	);
};

BillingCycleSelect.propTypes = {
	BoxProps: PropTypes.object,
	testId: PropTypes.string,
};

export default BillingCycleSelect;
