import React, { createContext, useContext, useMemo, useState } from 'react';
import useScreenSize from './screenSize';

/** @typedef LeftHandLayoutBreadcrumbsI
 * @property {string} label
 * @property {string} href
 */

/** @typedef LeftHandLayoutContentI
 * @property {React.ReactNode} title
 * @property {React.Dispatch<React.SetStateAction<LeftHandLayoutContentI["title"]>>} setBreadcrumbs
 * @property {LeftHandLayoutBreadcrumbsI[]} breadcrumbs
 * @property {React.Dispatch<React.SetStateAction<LeftHandLayoutContentI["breadcrumbs"]>>} setBreadcrumbs
 * @property {string | undefined} appPath
 * @property {string | undefined} path
 * @property {React.Dispatch<React.SetStateAction<LeftHandLayoutContentI["path"]>>} setPath
 * @property {ReturnType<typeof useScreenSize>} screenSize
 * @property {number} leftSize
 * @property {React.Dispatch<React.SetStateAction<LeftHandLayoutContentI["leftSize"]>>} setLeftSize
 * @property {number} topSize
 * @property {React.Dispatch<React.SetStateAction<LeftHandLayoutContentI["topSize"]>>} setTopSize
 * @property {number} topInnerX
 * @property {React.Dispatch<React.SetStateAction<LeftHandLayoutContentI["topInnerX"]>>} setTopInnerX
 * @property {number} topOuterY
 * @property {React.Dispatch<React.SetStateAction<LeftHandLayoutContentI["topOuterY"]>>} setTopOuterY
 * @property {JSX.Element?} linkComponent
 * @property {string?} linkProp
 */

const context = createContext(null);

/** @returns {LeftHandLayoutContentI} */
export function useLeftHandLayout() {
	return useContext(context);
}

/**
 * @typedef LeftHandLayoutProviderPropsI
 * @property {string} appPath
 * @property {JSX.Element?} linkComponent
 * @property {string?} linkProp
 * @property {React.ReactNode} children
 */

/**
 * @param {LeftHandLayoutProviderPropsI} props
 */
export function LeftHandLayoutProvider(props) {
	const { appPath, linkComponent, linkProp, children } = props;

	const [title, setTitle] = useState('');
	const [breadcrumbs, setBreadcrumbs] = useState([]);
	const [pathOverride, setPath] = useState(undefined);
	const [leftSize, setLeftSize] = useState(0);
	const [topSize, setTopSize] = useState(0);
	const [topInnerX, setTopInnerX] = useState(0);
	const [topOuterY, setTopOuterY] = useState(0);

	const path = pathOverride || appPath;

	const screenSize = useScreenSize();

	const value = useMemo(
		() => ({
			title,
			setTitle,
			breadcrumbs,
			setBreadcrumbs,
			appPath,
			path,
			setPath,
			screenSize,
			linkComponent,
			linkProp,
			leftSize,
			setLeftSize,
			topSize,
			setTopSize,
			topInnerX,
			setTopInnerX,
			topOuterY,
			setTopOuterY,
		}),
		[
			title,
			setTitle,
			breadcrumbs,
			setBreadcrumbs,
			appPath,
			path,
			setPath,
			screenSize,
			linkComponent,
			linkProp,
			leftSize,
			setLeftSize,
			topSize,
			setTopSize,
			topInnerX,
			setTopInnerX,
			topOuterY,
			setTopOuterY,
		],
	);

	return <context.Provider value={value}>{children}</context.Provider>;
}

export default useLeftHandLayout;
