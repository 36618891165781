import React from 'react';

import { useDispatch, useSelector } from 'react-redux';
import {
	actions as taskSchedulerActions,
	selectors as taskSchedulerSelectors,
} from 'modules/server/taskScheduler';

import LWCheckbox from 'components/atoms/LWCheckbox';
import LWTypography from 'components/common/LWTypography';

const ForceReboot = ({ 'data-testid': dataTestID }) => {
	const forceReboot = useSelector(taskSchedulerSelectors.getForceReboot);
	const dispatch = useDispatch();
	const setForceReboot = (arg) =>
		dispatch(taskSchedulerActions.setForceReboot({ forceReboot: arg }));
	return (
		<LWCheckbox
			checked={forceReboot}
			onChange={() => setForceReboot(!forceReboot)}
			ariaLabel="force reboot"
			value="force"
			pl={0}
			label={
				<>
					<LWTypography bold inline>
						Force Reboot:{' '}
					</LWTypography>
					<LWTypography inline>
						If the server is unresponsive, you can enable this option to perform
						a forceful reboot.
					</LWTypography>
				</>
			}
			dataTestID={dataTestID}
		/>
	);
};

export default ForceReboot;
