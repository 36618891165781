export const POWER_ACTION = {
	REBOOT: 'Reboot',
	SHUTDOWN: 'Shutdown',
	START: 'Start',
};

export const POWER_STATUS_DISPLAY = {
	ACTIVE: 'Active',
	BOOTING: 'Starting server',
	REBOOTING: 'Rebooting server',
	RUNNING: 'Running',
	SHUTDOWN: 'Shutdown',
	SHUTTING_DOWN: 'Shutting down server',
	UNAVAILABLE: 'Unavailable',
};

export const POWER_STATUS = {
	ON: 'on',
	OFF: 'off',
	SHUTDOWN: 'shutdown',
	STOP: 'stop',
	REBOOT: 'reboot',
	START: 'start',
	RESET: 'reset',
	UNKNOWN: 'unknown',
};

export const getPowerDisplay = (status) => {
	switch (status) {
		case POWER_STATUS.START:
			return POWER_STATUS_DISPLAY.BOOTING;
		case POWER_STATUS.REBOOT:
		case POWER_STATUS.RESET:
			return POWER_STATUS_DISPLAY.REBOOTING;
		case POWER_STATUS.ON:
			return POWER_STATUS_DISPLAY.RUNNING;
		case POWER_STATUS.OFF:
			return POWER_STATUS_DISPLAY.SHUTDOWN;
		case POWER_STATUS.SHUTDOWN:
		case POWER_STATUS.STOP:
			return POWER_STATUS_DISPLAY.SHUTTING_DOWN;
		case POWER_STATUS.UNKNOWN:
			return POWER_STATUS_DISPLAY.ACTIVE;
		default:
			return POWER_STATUS_DISPLAY.UNAVAILABLE;
	}
};

export const getNotificationLevelFromStatus = (status) => {
	switch (status) {
		case POWER_STATUS.ON:
			return 'normal';
		case POWER_STATUS.START:
		case POWER_STATUS.REBOOT:
		case POWER_STATUS.RESET:
		case POWER_STATUS.SHUTDOWN:
		case POWER_STATUS.STOP:
			return 'pending';
		case POWER_STATUS.OFF:
		case POWER_STATUS.UNKNOWN:
			return 'active';
		default:
			return undefined;
	}
};

export const isTransitioning = (status) =>
	[
		POWER_STATUS.SHUTDOWN,
		POWER_STATUS.STOP,
		POWER_STATUS.REBOOT,
		POWER_STATUS.START,
		POWER_STATUS.RESET,
	].includes(status);

/** @param {string} nocworxRemoteDetailsPowerStatus */
export const getBeyondHostingPowerStatus = (
	nocworxRemoteDetailsPowerStatus,
) => {
	if (nocworxRemoteDetailsPowerStatus) {
		switch (nocworxRemoteDetailsPowerStatus) {
			case 'Booting':
				return POWER_STATUS.START;
			case 'Rebooting':
				return POWER_STATUS.REBOOT;
			case 'Running':
				return POWER_STATUS.ON;
			case 'Shutdown':
				return POWER_STATUS.OFF;
			case 'Shutting Down':
				return POWER_STATUS.SHUTDOWN;
			case 'Unknown':
				return POWER_STATUS.UNKNOWN;
			default:
				return nocworxRemoteDetailsPowerStatus;
		}
	}
	return POWER_STATUS.UNKNOWN;
};

export const isRebooting = (powerStatus) =>
	[POWER_STATUS.REBOOT, POWER_STATUS.RESET].includes(powerStatus);

export const isRunning = (powerStatus) => powerStatus === POWER_STATUS.ON;

export const isStarting = (powerStatus) => powerStatus === POWER_STATUS.START;

export const isShutdown = (powerStatus) => powerStatus === POWER_STATUS.OFF;

export const isShuttingDown = (powerStatus) =>
	[POWER_STATUS.SHUTDOWN, POWER_STATUS.STOP].includes(powerStatus);

export const isUnknown = (powerStatus) => powerStatus === POWER_STATUS.UNKNOWN;

export const getTargetPowerStatus = (targetPower, uniqId) =>
	targetPower ? targetPower[uniqId]?.status : undefined;

export const getTargetPowerAction = (targetPower, uniqId) =>
	targetPower ? targetPower[uniqId]?.action : undefined;

export const isPowerTransitioning = (targetPower, uniqId, powerStatus) => {
	const targetPowerStatus = getTargetPowerStatus(targetPower, uniqId);
	return !targetPowerStatus ? false : powerStatus !== targetPowerStatus;
};
