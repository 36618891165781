import React from 'react';
import Stack from '@mui/material/Stack';
import { ReactComponent as LogoText } from 'images/lw-text-logo.svg';
import HookedLink from '../common/HookedLink';
import AlertMenu from '../common/AlertMenu';

export function LeftNavHeader() {
	return (
		<Stack
			direction="row"
			padding={200}
			paddingTop={300}
			paddingBottom={100}
			justifyContent="space-between"
			alignItems="center"
		>
			<HookedLink href="/">
				<LogoText />
			</HookedLink>
			<AlertMenu />
		</Stack>
	);
}

export default LeftNavHeader;
