import React from 'react';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import {
	House as HouseIcon,
	Cloud as CloudIcon,
	Star as StarIcon,
} from 'lucide-react';
import useLeftHandLayout from '../context';
import NavLink from '../common/NavLink';
import NavFooterLink from '../common/NavFooterLink';
import HookedLink from '../common/HookedLink';
import NavSection from '../common/NavSection';

function HookedNavLink(props) {
	const { navPath, ...linkProps } = props;
	const { href } = props;

	const { path } = useLeftHandLayout();

	const current = navPath || href;
	const isActive = current !== undefined && path === current;

	return <NavLink active={isActive} component={HookedLink} {...linkProps} />;
}

/**
 * @param {React.ComponentProps<typeof Stack>} props
 */
export function LeftNavList(props) {
	return (
		<Stack
			flexDirection="column"
			justifyContent="space-between"
			alignItems="stretch"
			padding={100}
			gap={200}
			useFlexGap
			{...props}
		>
			<NavSection>
				<HookedNavLink startIcon={<HouseIcon />} href="/">
					Home
				</HookedNavLink>
				<HookedNavLink startIcon={<CloudIcon />} href="/services">
					Services
				</HookedNavLink>
				<HookedNavLink startIcon={<StarIcon />} href="/favorites">
					Favorites
				</HookedNavLink>
			</NavSection>

			<NavSection label="Projects">
				<HookedNavLink href="/projects">Projects list</HookedNavLink>
				<HookedNavLink href="/projects/create">Create project</HookedNavLink>
			</NavSection>

			<NavSection label="Servers">
				<HookedNavLink href="/servers">My servers</HookedNavLink>
				<HookedNavLink href="/servers/ssh-keys">Public SSH keys</HookedNavLink>
				<HookedNavLink href="/servers/migration-center">
					Migration center
				</HookedNavLink>
				<HookedNavLink href="/servers/scheduled-tasks">
					Scheduled tasks
				</HookedNavLink>
			</NavSection>

			<NavSection label="Domains">
				<HookedNavLink href="/domain">Domains list</HookedNavLink>
				<HookedNavLink href="/shop/domain">Register a new domain</HookedNavLink>
				<HookedNavLink href="/domain/create-transfer">
					Transfer a domain
				</HookedNavLink>
				<HookedNavLink href="/domain/dns">DNS</HookedNavLink>
				<HookedNavLink href="/domain/ssl">SSL</HookedNavLink>
			</NavSection>

			<Box
				sx={{
					flex: '0% 1 1', // Expand to fill
					marginTop: (theme) => `-${theme.spacing(200)}`, // But not twice the gap
				}}
			>
				{/* Divider spacing */}
			</Box>

			<Stack flexDirection="column" alignItems="stretch">
				<NavFooterLink href="https://www.liquidweb.com/blog/" target="_blank">
					Blog
				</NavFooterLink>
				<NavFooterLink href="https://status.liquidweb.com/" target="_blank">
					Liquid Web status
				</NavFooterLink>
			</Stack>
		</Stack>
	);
}

export default LeftNavList;
