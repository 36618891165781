import { useDispatch } from 'react-redux';
import snackbarActions from 'modules/snackbar/snackbarActions';
import { getErrorStringHelper } from 'utility/redux/selectorHelperFunctions/apiModules';

/** @param {string} [message] */
const useSnackbarError = (message) => {
	const dispatch = useDispatch();

	return (error) =>
		dispatch(
			snackbarActions.pushMessage({
				variant: 'error',
				message: message || getErrorStringHelper(error),
			}),
		);
};

export default useSnackbarError;
