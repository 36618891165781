import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import dayjs from 'dayjs';
import Grid from '@material-ui/core/Grid';
import WarningIcon from '@material-ui/icons/WarningRounded';
import LWDialog from 'components/common/LWDialog';
import LabeledIcon from 'components/atoms/LabeledIcon';
import LabeledText from 'components/atoms/LabeledText';
import LWTypography from 'components/common/LWTypography';
import ConfirmCancel from 'components/common/Dialogs/ConfirmCancel';
import ForceReboot from 'components/connectedMolecules/ForceReboot';
import {
	actions as taskSchedulerActions,
	selectors as taskSchedulerSelectors,
} from 'modules/server/taskScheduler';
import supportedTasks from 'modules/server/taskScheduler/supportedTasks';
import TaskScheduler, {
	useTaskScheduler,
} from 'components/common/TaskScheduler';
import CurrentTaskList from './CurrentTaskList';

const TaskSchedulerDialog = () => {
	const dispatch = useDispatch();

	const params = useSelector(taskSchedulerSelectors.getApiParams);
	const hostname = useSelector(taskSchedulerSelectors.getHostname);
	const isOpen = useSelector(taskSchedulerSelectors.getIsOpen);
	const task = useSelector(taskSchedulerSelectors.getTask);
	const canAutoReboot = useSelector(taskSchedulerSelectors.getCanAutoReboot);

	const { uniq_id: uniqId } = params;

	const {
		deferError,
		deferUntil,
		deferUntilDayjs,
		isValidDeferTime,
		mode,
		resetDeferUntil,
		setDeferUntil,
		setMode,
	} = useTaskScheduler();

	const { label: taskLabel, selectors: taskApiSelector } =
		supportedTasks[task] || {};

	const isLoading = useSelector(taskApiSelector?.isLoading || (() => false));
	const closeDialog = () => {
		resetDeferUntil();
		dispatch(taskSchedulerActions.closeDialog());
	};

	const scheduleDefer = () => {
		const now = dayjs();
		const deferSeconds = Math.floor(
			dayjs.duration(deferUntilDayjs.diff(now)).asSeconds(),
		);

		dispatch(
			taskSchedulerActions.schedule({
				params,
				task,
				deferSeconds,
			}),
		);
	};

	const scheduleNow = () => {
		dispatch(
			taskSchedulerActions.schedule({
				params,
				task,
			}),
		);
	};

	const handleConfirm = () => {
		if (mode === 'deferred') {
			scheduleDefer();
		} else {
			scheduleNow();
		}

		resetDeferUntil();
	};

	return (
		<LWDialog
			open={isOpen}
			title="Task Scheduler"
			onClose={closeDialog}
			onCancel={closeDialog}
			contentPadding
			fullWidth
		>
			<Grid container direction="column" spacing={4}>
				<Grid item>
					{hostname && (
						<LabeledText
							inline
							variant="boldedLabel"
							label="Server"
							text={hostname}
							BoxProps={{ mb: 2 }}
						/>
					)}
					<LabeledText
						inline
						variant="boldedLabel"
						label="Type"
						text={taskLabel}
						BoxProps={{ mb: 3 }}
					/>
					<TaskScheduler
						datetimeValue={deferUntil}
						onDatetimeChange={setDeferUntil}
						mode={mode}
						onModeChange={setMode}
						dateTimeError={deferError}
					/>
					{task === 'reboot' && canAutoReboot && <ForceReboot />}
					{/*  TODO: figure out if the following block of code is needed? */}
					{task === 'reboot' && !canAutoReboot && (
						<LWTypography variant="body1">
							A reboot request will be submitted, and a Helpful Human will
							respond shortly.
						</LWTypography>
					)}
					{uniqId && <CurrentTaskList uniqId={uniqId} />}
				</Grid>
				{task === 'resize' && (
					<Grid item>
						<LabeledIcon
							text="Server will perform a reboot to complete the resize."
							icon={<WarningIcon />}
							color="error.main"
						/>
					</Grid>
				)}
				<Grid item>
					<ConfirmCancel
						confirmText={
							mode === 'deferred' ? `Schedule ${taskLabel}` : taskLabel
						}
						cancel={closeDialog}
						isLoading={isLoading}
						disabled={!isValidDeferTime}
						confirm={handleConfirm}
					/>
				</Grid>
			</Grid>
		</LWDialog>
	);
};

export default TaskSchedulerDialog;
