import React, { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';

import CircleHelpIcon from 'undercurrent/icons/lucide/CircleHelp';
import IconButton from '@mui/material/IconButton';
import Link from '@mui/material/Link';
import ListItemText from '@mui/material/ListItemText';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Tooltip from '@mui/material/Tooltip';

import chatActions from 'modules/chat/actions';
import HookedLink from '../common/HookedLink';

export function TopNavSupport() {
	const [menuElement, setMenuElement] = useState();
	const open = menuElement !== undefined;
	const dispatch = useDispatch();

	const handleChat = useCallback(() => {
		dispatch(chatActions.startChat());
		setMenuElement();
	}, [dispatch]);

	return (
		<>
			<Tooltip title="Support">
				<IconButton
					onClick={({ currentTarget }) => setMenuElement(currentTarget)}
				>
					<CircleHelpIcon fontSize="small" />
				</IconButton>
			</Tooltip>
			<Menu
				open={open}
				anchorEl={menuElement}
				anchorOrigin={{
					vertical: 'bottom',
					horizontal: 'right',
				}}
				transformOrigin={{
					vertical: 'top',
					horizontal: 'right',
				}}
				onClose={() => setMenuElement()}
			>
				<MenuItem
					component={HookedLink}
					href="/support/mytickets"
					role="menuitem"
				>
					<ListItemText primary="My tickets" />
				</MenuItem>
				<MenuItem onClick={handleChat} role="menuitem">
					<ListItemText primary="Chat with a human" />
				</MenuItem>
				<MenuItem
					component={Link}
					href="https://www.liquidweb.com/help-docs/"
					target="_blank"
					role="menuitem"
				>
					<ListItemText primary="Help Docs" />
				</MenuItem>
				{/* TODO DEV-4003 */}
				<MenuItem>
					<ListItemText primary="My support passphrase" />
				</MenuItem>
			</Menu>
		</>
	);
}

export default TopNavSupport;
