import { combineReducers } from 'redux';

import creditcardReducer from './creditcard/creditcardReducer';
import { reducer as changeMonthlyBillDay } from './changeMonthlyBillDayModule';
import invoice from './invoice/invoiceReducer';
import paypal from './paypal/paypalReducer';
import payment from './payment/paymentReducer';

export default combineReducers({
	changeMonthlyBillDay,
	creditcard: creditcardReducer,
	invoice,
	paypal,
	payment,
});
