import countryRegionData from 'country-region-data/dist/data-umd';

const countriesMappedToOptions = countryRegionData
	.filter(
		(country) =>
			// from LWConfig/Country.yaml
			!['CU', 'IR', 'KP', 'SY', 'US'].includes(country.countryShortCode),
	)
	.map((country) => ({
		label: country.countryName,
		value: country.countryShortCode,
	}));

const countriesMappedToOptionsUSFirst = [
	{ label: 'United States', value: 'US' },
	...countriesMappedToOptions,
];

const countryLabelMap = Object.fromEntries(
	countriesMappedToOptionsUSFirst.map(({ label, value }) => [value, label]),
);

export { countryLabelMap };
export default countriesMappedToOptionsUSFirst;
