import { createGlobalStyle, css } from 'styled-components';

const baseStyle = css`
	html {
		background-color: #f5f5f5;
	}
	body {
		margin: 0;
		font-family: 'FK Grotesk Neue';
		overflow-x: hidden; /* This hides the sidesheet from mobile when it should be hidden. */
	}
`;

// Drawer open causes padding on body
const noPaddingDrawer = css`
	body {
		padding: 0 !important;
	}
`;

// Fix box sizing so calc isn't required everywhere with padding
const boxSizingFix = css`
	html {
		box-sizing: border-box;
	}
	*,
	*:before,
	*:after {
		box-sizing: inherit;
	}
`;

const GlobalStyles = createGlobalStyle`
	${baseStyle}
	${boxSizingFix}
	${noPaddingDrawer}
`;

const GlobalStylesStorybook = createGlobalStyle`
${baseStyle}
${boxSizingFix}
${noPaddingDrawer}
`;

export { GlobalStylesStorybook };
export default GlobalStyles;
