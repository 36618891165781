import React, { useState } from 'react';
import { ReactComponent as LogoText } from 'images/lw-text-logo.svg';
import Stack from '@mui/material/Stack';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import MenuIcon from 'undercurrent/icons/lucide/Menu';
import useLeftHandLayout from '../context';
import { useNavTopInnerX, useNavTopOuterY, useNavTopSize } from '../navSetter';
import AlertMenu from '../common/AlertMenu';
import TopNavSearch from '../common/SearchButton';

const TOPNAV_SIZE_PX = 60;
const TOPNAV_INNER_X_PX = 24;
const TOPNAV_OUTER_Y_PX = 0;

export function LeftNav() {
	const { title } = useLeftHandLayout();
	useNavTopSize(TOPNAV_SIZE_PX);
	useNavTopInnerX(TOPNAV_INNER_X_PX);
	useNavTopOuterY(TOPNAV_OUTER_Y_PX);

	const [open, setOpen] = useState(false);
	console.log('menu', open); // TODO DEV-4171

	return (
		<Stack
			component="nav"
			position="fixed"
			top={0}
			left={0}
			right={0}
			height={TOPNAV_SIZE_PX}
			overflow="hidden"
			direction="row"
			alignItems="center"
			paddingY={100}
			paddingX={`${TOPNAV_INNER_X_PX}px`}
			zIndex={(theme) => theme.zIndex.appBar}
			bgcolor={(theme) => theme.palette.uc.bg.weak}
		>
			<Tooltip title="Menu">
				<IconButton
					onClick={() => {
						setOpen(true);
					}}
				>
					<MenuIcon fontSize="small" />
				</IconButton>
			</Tooltip>
			<Stack flex="0% 1 1" overflow="auto" direction="row" useFlexGap gap={100}>
				{title || <LogoText />}
			</Stack>
			<AlertMenu />
			<TopNavSearch />
		</Stack>
	);
}

export default LeftNav;
