import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import getIsTouchDevice from 'is-touch-device';
import { useTheme } from '@material-ui/core/styles';

import Box from '@material-ui/core/Box';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import Info from '@material-ui/icons/Info';

import Popper from 'components/atoms/Popper';

const STooltip = styled((props) => (
	<Tooltip
		classes={{ popper: props.className, tooltip: 'tooltip' }}
		{...props}
	/>
))`
	& button {
		color: ${(p) => p.color || p.theme.palette.secondary.main};
		opacity: ${(p) => (p.color ? 1 : 0.5)};
	}
	& button:hover {
		opacity: 1;
	}
`;

const lwTootiptestIds = { lwToolTip: 'lwToolTip' };

const LWTooltip = ({ children, boxSize = 5.5, icon, ...rest }) => {
	const theme = useTheme();
	const tooltipRef = useRef();
	const size = `${theme.spacing(boxSize)}px`;
	const [isTouchDevice, setIsTouchDevice] = useState(getIsTouchDevice());
	const [targetInCenter, setTargetInCenter] = useState(false);
	const [open, setOpen] = useState(false);

	const handleClose = () => {
		setOpen(false);
	};

	const handleOpen = () => {
		setOpen(true);
	};

	useEffect(() => {
		if (!window.IntersectionObserver) {
			// We can't assume this exists in all browsers. Treat this like a desktop.
			setIsTouchDevice(false);
		} else {
			const myObserver = new IntersectionObserver(
				(entries) => {
					// If still mounted
					if (tooltipRef.current) {
						setTargetInCenter(entries[0].isIntersecting);
					}
				},
				{
					rootMargin: '-10% 0px -70% 0px',
					threshold: 1.0,
				},
			);
			myObserver.observe(tooltipRef.current);
		}
	}, [tooltipRef]);

	return (
		<STooltip
			data-testid={lwTootiptestIds.lwToolTip}
			title={children}
			placement={isTouchDevice ? 'bottom' : 'right'}
			PopperComponent={Popper}
			{...(isTouchDevice ? { open: targetInCenter || open } : {})}
			enterTouchDelay={0}
			leaveTouchDelay={300000}
			onClose={handleClose}
			onOpen={handleOpen}
			{...rest}
		>
			<Box
				display="flex"
				justifyContent="center"
				alignItems="center"
				height={size}
				width={size}
				ref={tooltipRef}
			>
				<IconButton>{icon || <Info />}</IconButton>
			</Box>
		</STooltip>
	);
};

LWTooltip.propTypes = {
	boxSize: PropTypes.number,
	icon: PropTypes.element,
};

export { STooltip, lwTootiptestIds };
export default LWTooltip;
