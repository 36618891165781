import React from 'react';

import Box from '@material-ui/core/Box';
import CircularProgress from '@material-ui/core/CircularProgress';
import FormControl from '@material-ui/core/FormControl';
import InputAdornment from '@material-ui/core/InputAdornment';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import { maybeRound } from 'components/cart/Item/CartItemDiscounts';
import { createDurationDisplay } from 'containers/pages/shop/ConfigPackage/BillingCycleSelect';
import packageConfigActions from 'modules/basket/packageConfig/actions';
import packageConfigSelectors from 'modules/basket/packageConfig/selectors';
import useBillingCycleList from 'modules/queries/billing/cycle/useList';
import { useDispatch, useSelector } from 'react-redux';

const createDurationDisplayWithSavings = ({ discount, friendDisplay }) => {
	if (!friendDisplay) return '';

	if (!discount) {
		return createDurationDisplay(friendDisplay);
	}

	return `${createDurationDisplay(friendDisplay)} -
										(Save ${maybeRound(discount * 100)}%)`;
};

/**
 * @param {object} props
 * @param {import('banana-stand/packageConfig/packageConfigContext').BananaStandCycleI[]} props.billingCycles
 * @returns
 */
const BillingCycleSelect = ({ billingCycles }) => {
	const dispatch = useDispatch();

	const { selectedBillingCycleId } = useSelector(
		packageConfigSelectors.getStateSlice,
	);

	const { data: billingCycleData, isLoading } = useBillingCycleList();

	const handleBillingCycleChange = (event) => {
		dispatch(
			packageConfigActions.setBillingCycle({ cycleId: event.target.value }),
		);
	};

	const cyclesData = billingCycleData?.cycles || {};

	return (
		<FormControl variant="outlined" disabled={isLoading}>
			<InputLabel>Billing Cycle</InputLabel>
			<Select
				label="Billing Cycle"
				name="billing-cycle"
				onChange={handleBillingCycleChange}
				value={selectedBillingCycleId}
				endAdornment={
					isLoading ? (
						<InputAdornment position="end">
							{/** avoids collision with arrow */}
							<Box pr={2}>
								<CircularProgress size={24} />
							</Box>
						</InputAdornment>
					) : null
				}
			>
				{billingCycles.map(({ id, discount, cycle }) => (
					<MenuItem key={id} value={id}>
						{createDurationDisplayWithSavings({
							discount,
							friendDisplay: cyclesData[cycle]?.display_name,
						})}
					</MenuItem>
				))}
			</Select>
		</FormControl>
	);
};

export default BillingCycleSelect;
