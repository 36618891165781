// @ts-check
import { useQuery } from '@tanstack/react-query';
import api from 'modules/queries/api';

/**
 * @param {import('club-sauce/public/contact/index.raw').LWApiPublicContactListParamsRawI} params
 * @param {object} options
 * @param {import('@tanstack/react-query').UseQueryOptions['keepPreviousData']} [options.keepPreviousData]
 * @param {import('@tanstack/react-query').UseQueryOptions['enabled']} [options.enabled]
 */
const useContactList = (params, { keepPreviousData, enabled } = {}) =>
	useQuery({
		queryKey: ['/contact/list', params],
		queryFn: () => api.contact.apiRaw.list(params),
		keepPreviousData,
		enabled,
	});

export default useContactList;
