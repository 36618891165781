import React from 'react';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import useLeftHandLayout, { LeftHandLayoutProvider } from './context';
import SmallNav from './SmallNav';
import LeftNav from './LeftNav';
import TopNav from './TopNav';

/**
 * @param {React.ComponentProps<typeof Stack>} props
 */
export function LeftHandLayoutContent(props) {
	const { children, ...stackProps } = props;

	const { screenSize, leftSize, topSize, topInnerX } = useLeftHandLayout();

	return (
		<Stack
			paddingLeft={`${leftSize}px`}
			bgcolor={(theme) => theme.palette.uc.bg.weak}
			{...stackProps}
		>
			{screenSize === 'small' && (
				<>
					<SmallNav />
					<Box
						component="main"
						flex="0% 1 1"
						flexDirection="column"
						alignItems="flex-start"
						borderRadius={(theme) => theme.borderRadius.roundedSm}
						paddingTop={`${topSize}px`}
						bgcolor={(theme) => theme.palette.uc.bg.main}
					>
						{children}
					</Box>
				</>
			)}
			{(screenSize === 'medium' || screenSize === 'large') && (
				<>
					<LeftNav />
					<TopNav />
					<Box
						component="main"
						flex="0% 1 1"
						flexDirection="column"
						alignItems="flex-start"
						borderRadius={(theme) => theme.borderRadius.roundedSm}
						paddingTop={`${topSize}px`}
						paddingX={`${topInnerX}px`}
						paddingBottom={200}
						margin={100}
						bgcolor={(theme) => theme.palette.uc.bg.main}
					>
						{children}
					</Box>
				</>
			)}
		</Stack>
	);
}

/**
 * @typedef {React.ComponentProps<typeof Stack>} LeftHandLayoutPropsI
 * @property {string} appPath
 * @property {JSX.Element?} linkComponent
 * @property {string?} linkProp
 */

/**
 * @param {LeftHandLayoutPropsI} props
 */
export function LeftHandLayout(props) {
	const { appPath, linkComponent, linkProp, ...stackProps } = props;

	return (
		<LeftHandLayoutProvider
			appPath={appPath}
			linkComponent={linkComponent}
			linkProp={linkProp}
		>
			<LeftHandLayoutContent {...stackProps} />
		</LeftHandLayoutProvider>
	);
}

export default LeftHandLayout;
