import React, { useState } from 'react';

import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import Link from '@mui/material/Link';
import Menu from '@mui/material/Menu';
import Typography from '@mui/material/Typography';

import ArrowRightIcon from 'undercurrent/icons/lucide/ArrowRight';
import ChevronDownIcon from 'undercurrent/icons/lucide/ChevronDown';
import ChevronUpIcon from 'undercurrent/icons/lucide/ChevronUp';
import CloudIcon from 'undercurrent/icons/lucide/Cloud';
import LayersIcon from 'undercurrent/icons/lucide/Layers2';
import ServerIcon from 'undercurrent/icons/lucide/Server';
import WordpressIcon from 'undercurrent/icons/platform/WordPress';

import CreateItem from '../common/CreateItem';
import HookedLink from '../common/HookedLink';

export function TopNavCreate() {
	const [menuElement, setMenuElement] = useState();
	const open = menuElement !== undefined;

	return (
		<>
			<Button
				color="highlight"
				variant={open ? 'primary' : 'secondary'}
				endIcon={open ? <ChevronUpIcon /> : <ChevronDownIcon />}
				onClick={({ currentTarget }) => setMenuElement(currentTarget)}
			>
				Create and deploy
			</Button>
			<Menu
				open={open}
				anchorEl={menuElement}
				anchorOrigin={{
					vertical: 'bottom',
					horizontal: 'right',
				}}
				transformOrigin={{
					vertical: 'top',
					horizontal: 'right',
				}}
				onClose={() => setMenuElement()}
			>
				<CreateItem
					component={Link}
					href="https://www.liquidweb.com/configuration/cloud-studio/?type=vps"
					target="_blank"
					icon={
						<LayersIcon
							sx={(theme) => ({
								fontSize: `${theme.size.sm}!important`,
							})}
						/>
					}
					description={
						<Typography
							variant="body-xs"
							component="p"
							sx={(theme) => ({
								marginTop: theme.spacing(50),
							})}
						>
							Control, customization, security
						</Typography>
					}
				>
					<Typography variant="label-xl">VPS hosting</Typography>
				</CreateItem>
				<CreateItem
					component={Link}
					href="https://www.liquidweb.com/cloud-hosting/dedicated-cloud-server/"
					target="_blank"
					icon={
						<CloudIcon
							sx={(theme) => ({
								fontSize: `${theme.size.sm}!important`,
							})}
						/>
					}
					description={
						<Typography
							variant="body-xs"
							component="p"
							sx={(theme) => ({
								marginTop: theme.spacing(50),
							})}
						>
							Reliable, scalable, secure
						</Typography>
					}
				>
					<Typography variant="label-xl">Cloud hosting</Typography>
				</CreateItem>
				<CreateItem
					component={Link}
					href="https://www.liquidweb.com/dedicated-server-hosting/managed-servers/"
					target="_blank"
					icon={
						<ServerIcon
							sx={(theme) => ({
								fontSize: `${theme.size.sm}!important`,
							})}
						/>
					}
					description={
						<Typography
							variant="body-xs"
							component="p"
							sx={(theme) => ({
								marginTop: theme.spacing(50),
							})}
						>
							Private, secure, fully featured
						</Typography>
					}
				>
					<Typography variant="label-xl">Dedicated servers</Typography>
				</CreateItem>
				<CreateItem
					component={Link}
					href="https://www.liquidweb.com/wordpress-hosting/"
					target="_blank"
					icon={
						<WordpressIcon
							sx={(theme) => ({
								fontSize: `${theme.size.sm}!important`,
								path: {
									fill: theme.palette.uc.icon.main,
								},
							})}
						/>
					}
					description={
						<Typography
							variant="body-xs"
							component="p"
							sx={(theme) => ({
								marginTop: theme.spacing(50),
							})}
						>
							Optimized, experienced, scalable
						</Typography>
					}
				>
					<Typography variant="label-xl">WordPress hosting</Typography>
				</CreateItem>
				<Divider />
				<CreateItem
					component={HookedLink}
					href="/shop/marketplace"
					onClick={() => setMenuElement()}
					endIcon={<ArrowRightIcon />}
				>
					Visit Marketplace
				</CreateItem>
			</Menu>
		</>
	);
}

export default TopNavCreate;
