import {
	all,
	call,
	put,
	select,
	spawn,
	take,
	takeLatest,
} from 'redux-saga/effects';
import { actions as serverRebootAPIActions } from 'modules/api/server/power/rebootModule';
import { actions as serverResetAPIActions } from 'modules/api/server/power/resetModule';
import { actions as usertaskListActions } from 'modules/api/usertask/listModule';
import snackbarActions from 'modules/snackbar/snackbarActions';
import taskSchedulerSelectors from 'modules/server/taskScheduler/selectors';
import targetPowerActions from 'modules/server/targetPower/actions';
import { POWER_ACTION, POWER_STATUS } from 'utility/powerStatus';
import actions from './actions';
import * as selectors from './selectors';
import { successMessages } from './constants';

const createDeferHeaders = (deferSeconds = null) => {
	const headers = {};

	if (deferSeconds) {
		headers['X-Meta-Timing-Defer'] = deferSeconds;
	}

	return headers;
};

function* callRebootAPI({ action, uniqId, deferSeconds = null }) {
	yield put(
		action.fetchRaw({
			data: { params: { uniq_id: uniqId } },
			headers: createDeferHeaders(deferSeconds),
		}),
	);
}

function* refreshUsertaskList() {
	const uniqId = yield select(selectors.getUniqId);

	yield put(usertaskListActions.clear());
	yield put(
		usertaskListActions.fetch({
			uniq_id: uniqId,
			alsowith: ['action'],
			status: 'pending',
		}),
	);
}

function* handleRebootSuccess(payload) {
	const { code, requested } = payload;

	let message;
	if (code) {
		message = successMessages.scheduled;
	} else if (requested) {
		message = successMessages.requested;
	} else {
		message = successMessages.rebooted;
	}

	if (code) {
		yield call(refreshUsertaskList);
	}

	if (yield select(selectors.getIsDialogOpen)) {
		yield put(actions.closeDialog());
	}

	yield put(snackbarActions.pushMessage({ message, variant: 'success' }));
}

function* handleInvokeReboot({ payload: requestPayload }) {
	const force = yield select(taskSchedulerSelectors.getForceReboot);

	const action = force ? serverResetAPIActions : serverRebootAPIActions;

	yield put(action.clear());

	const [{ type, payload }] = yield all([
		take([action.setType, action.errorType]),
		call(callRebootAPI, { action, ...requestPayload }),
	]);

	if (type === action.setType) {
		if (!requestPayload?.deferSeconds) {
			yield put(
				targetPowerActions.setServerTargetPower({
					uniqId: requestPayload?.uniqId,
					action: POWER_ACTION.REBOOT,
					status: POWER_STATUS.ON,
				}),
			);
		}
		yield call(handleRebootSuccess, payload);
	}
}

function* watchInvokeReboot() {
	yield takeLatest(actions.SERVER_REBOOT_INVOKE_REBOOT, handleInvokeReboot);
}

export default function* rebootSagas() {
	yield spawn(watchInvokeReboot);
}
