import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useQueryClient } from '@tanstack/react-query';
import {
	getIsLoadingUser,
	getAuthToken,
	getOidcStateSlice,
} from 'modules/auth/authSelectors';
import appConfigSelectors from 'modules/appConfig/selectors';
import api from 'modules/queries/api';

export function AuthConnector() {
	const queryClient = useQueryClient();

	const isLoading = useSelector(getIsLoadingUser);
	const authToken = useSelector(getAuthToken);
	const guestCredentials = useSelector(appConfigSelectors.getGuestCredentials);
	const oidcState = useSelector(getOidcStateSlice);

	const userId = oidcState.getIn(['user', 'profile', 'sub']);

	// Invalidate endpoints on user change
	useEffect(() => {
		if (userId === 0) return; // Keep userId in deps
		queryClient.invalidateQueries();

		// Some day we may need to keep _some_ data as the user logs in, see:
		// const queryCache = queryClient.getQueryCache();
		// const queryKeys = queryCache.getAll().map(cache => cache.queryKey);
	}, [userId, queryClient]);

	// Handle auth changes
	useEffect(() => {
		// Pause sooner
		api.apiPause();

		if (isLoading) return undefined;

		if (authToken) {
			api.apiSetAuthorization(`Bearer ${authToken}`);
		} else {
			api.apiSetBasicAuthorization(
				guestCredentials.username,
				guestCredentials.password,
			);
		}

		// debounce to allow for oidc to catchup
		const timer = setTimeout(() => {
			api.apiUnpause();
		}, 1);

		return () => {
			clearTimeout(timer);
		};
	}, [authToken, guestCredentials, isLoading]);

	return null;
}

export default AuthConnector;
