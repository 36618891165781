import { createSelector } from 'reselect';
import { selectors as notificationsSelectors } from 'modules/api/notifications/currentModule';

const getStateSlice = (state) => state.statusSheet;
const newestNotificationsSelector = (state) =>
	notificationsSelectors.newestPerAsset(state);

const isOpen = createSelector(
	getStateSlice,
	(statusSheet) => statusSheet.isOpen,
);

const hiddenNotifications = createSelector(
	getStateSlice,
	(statusSheet) => statusSheet.hiddenNotifications,
);

const pendingNotifications = createSelector(
	getStateSlice,
	newestNotificationsSelector,
	hiddenNotifications,
	(statusSheet, newestNotifications, hiddenNotificationIds) => {
		const pending =
			newestNotifications &&
			newestNotifications.filter(
				(notification) =>
					// using startsWith since in dev there are different Provisioning systems that have extra values appended
					(notification.get('system').startsWith('Provisioning') ||
						notification.get('system') === 'Nocworx Workflow' ||
						notification.get('system') === 'Bare Metal') &&
					notification.get('severity') !== 'Critical',
			);
		return (
			pending &&
			pending.filter(
				(notification) =>
					!hiddenNotificationIds.includes(notification.get('id')) &&
					notification.get('id') !== 0,
			)
		);
	},
);

const height = createSelector(
	getStateSlice,
	(statusSheet) => statusSheet.height,
);

// eslint-disable-next-line import/prefer-default-export
export { isOpen, pendingNotifications, height };
