import React, { useState } from 'react';
import { styled, Collapse, Typography, Stack, SvgIcon } from '@mui/material';
import {
	ChevronDown as ChevronDownIcon,
	ChevronUp as ChevronUpIcon,
} from 'lucide-react';
import 'undercurrent';

/**
 * @typedef {import('@mui/material').SxProps<import('@mui/material').Theme>} MuiSxStylesI
 */

/** @type MuiSxStylesI */
const buttonHoverStyles = {
	bgcolor: (theme) => theme.palette.uc.bg.medium,
	outline: 'none',
};

/** @type MuiSxStylesI */
const buttonStyles = {
	cursor: 'pointer',
	height: (theme) => theme.size.sm,
	display: 'flex',
	alignItems: 'stretch',
	justifyContent: 'center',
	color: (theme) => theme.palette.uc.text.weak,
	bgcolor: 'transparent',
	paddingY: 50,
	paddingX: 100,
	border: 'none',
	borderRadius: (theme) => theme.borderRadius.roundedSm,
	gap: 100,
	':hover': buttonHoverStyles,
	':active': buttonHoverStyles,
	':focus-visible': {
		outlineOffset: '2px',
		outlineWidth: '2px',
		outlineStyle: 'solid',
		outlineColor: (theme) => theme.palette.uc.highlight.border.main,
	},
};

const SSection = styled('section')({});
const SButton = styled('button')({});

/**
 * @typedef {React.ComponentProps<typeof SSection>} NavSectionPropsI
 * @property {string?} label
 */

/**
 * @param {NavSectionPropsI} props
 */
export function NavSection(props) {
	const { label, children, ...sectionProps } = props;

	const [open, setOpen] = useState(true);

	return (
		<SSection {...sectionProps}>
			{label && (
				<SButton onClick={() => setOpen((c) => !c)} sx={buttonStyles}>
					<Typography variant="body-xs">{label}</Typography>
					<SvgIcon fontSize="xs">
						{open ? <ChevronUpIcon /> : <ChevronDownIcon />}
					</SvgIcon>
				</SButton>
			)}
			<Collapse in={open || !label}>
				<Stack
					flexDirection="column"
					justifyContent="space-between"
					alignItems="stretch"
					gap={50}
					useFlexGap
				>
					{children}
				</Stack>
			</Collapse>
		</SSection>
	);
}

export default NavSection;
