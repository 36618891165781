import { useEffect } from 'react';
import useLeftHandLayout from './context';

/** Create a setter hook  */
export function createNavSetter(setterKey, defaultValue) {
	return (value) => {
		const setter = useLeftHandLayout()[setterKey];

		useEffect(() => {
			setter(value);

			return () => {
				setter((current) => {
					// Only clear if it is what we set
					if (current !== value) return current;
					return defaultValue;
				});
			};
		}, [value, setter]);
	};
}

/**
 * Set the nav title
 * @param {import('./context').LeftHandLayoutContentI["title"]} title
 */
export const useNavTitle = createNavSetter('setTitle', '');

/**
 * Set the nav breadcrumbs
 * @param {import('./context').LeftHandLayoutContentI["breadcrumbs"]} breadcrumbs
 */
export const useNavBreadcrumbs = createNavSetter('setBreadcrumbs', []);

/**
 * Override the active path in the nav sidebar
 * @param {import('./context').LeftHandLayoutContentI["path"]} path
 */
export const useNavActivePath = createNavSetter('setPath', undefined);

/**
 * Set the nav leftSize
 * @private For layout internal use
 * @param {import('./context').LeftHandLayoutContentI["leftSize"]} leftSize
 */
export const useNavLeftSize = createNavSetter('setLeftSize', 0);

/**
 * Set the nav topSize
 * @private For layout internal use
 * @param {import('./context').LeftHandLayoutContentI["topSize"]} topSize
 */
export const useNavTopSize = createNavSetter('setTopSize', 0);

/**
 * Set the nav topInnerX
 * @private For layout internal use
 * @param {import('./context').LeftHandLayoutContentI["topInnerX"]} topInnerX
 */
export const useNavTopInnerX = createNavSetter('setTopInnerX', 0);

/**
 * Set the nav topOuterY
 * @private For layout internal use
 * @param {import('./context').LeftHandLayoutContentI["topOuterY"]} topOuterY
 */
export const useNavTopOuterY = createNavSetter('setTopOuterY', 0);

export default createNavSetter;
