const actions = {
	SERVER_OPEN_CONSOLE: 'SERVER_OPEN_CONSOLE',
	openConsole: ({ uniqId } = {}) => ({
		type: actions.SERVER_OPEN_CONSOLE,
		uniqId,
	}),
	SERVER_INIT_VNC_CONSOLE: 'SERVER_INIT_VNC_CONSOLE',
	initVncConsole: ({ uniqId }) => ({
		type: actions.SERVER_INIT_VNC_CONSOLE,
		uniqId,
	}),
};

export default actions;
