import React, { useCallback } from 'react';
import IconButton from '@mui/material/IconButton';
import SearchIcon from 'undercurrent/icons/lucide/Search';
import Tooltip from '@mui/material/Tooltip';
import useKeyCmd, { CMD_KEY } from 'utility/hooks/useKeyCmd';

export function TopNavSearch() {
	const handleSearch = useCallback(() => {
		// TODO DEV-4011
		console.log('SEARCH THINGS');
	}, []);

	useKeyCmd('KeyK', handleSearch);

	return (
		<Tooltip title={`Search ${CMD_KEY} K`}>
			<IconButton onClick={handleSearch}>
				<SearchIcon fontSize="small" />
			</IconButton>
		</Tooltip>
	);
}

export default TopNavSearch;
