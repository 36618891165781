import React, { useState } from 'react';
import Badge from '@mui/material/Badge';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import BellIcon from 'undercurrent/icons/lucide/Bell';
import useNotificationsCurrent from 'modules/queries/notification/useCurrent';
import { Tooltip } from '@mui/material';
import HookedLink from './HookedLink';

/**
 * @typedef AlertItemPropsI
 * @property {import('club-sauce/public/notifications/index.raw').LWApiPublicNotificationDetailsResultRawI} alert
 */

/**
 * @param {AlertItemPropsI} props
 */
export function AlertItem(props) {
	const { alert, onClose } = props;

	// TODO DEV-4113 swtich by alert type
	// see: src/containers/structural/notification/NotificationDrawer/NotificationDrawer.jsx
	const href = alert.uniq_id ? `/servers/${alert.uniq_id}` : '/';
	return (
		<MenuItem component={HookedLink} href={href} onClick={onClose}>
			{alert.description}
		</MenuItem>
	);
}

export function AlertMenu() {
	const [menuElement, setMenuElement] = useState();
	const open = menuElement !== undefined;

	const { data: alertsData } = useNotificationsCurrent(undefined, {
		refetchInterval: 30000,
	});
	const alerts = alertsData?.items || [];

	return (
		<>
			<Tooltip title="Alerts">
				<IconButton
					variant="ghost"
					onClick={({ currentTarget }) => setMenuElement(currentTarget)}
				>
					<Badge
						color={alerts.length > 0 ? 'error' : undefined}
						overlap="circular"
						variant="dot"
					>
						<BellIcon />
					</Badge>
				</IconButton>
			</Tooltip>
			<Menu open={open} anchorEl={menuElement} onClose={() => setMenuElement()}>
				{alerts.length < 1 && <MenuItem disabled>No unread alerts</MenuItem>}
				{alerts.map((alert) => (
					<AlertItem
						key={alert.id}
						alert={alert}
						onClose={() => setMenuElement()}
					/>
				))}
			</Menu>
		</>
	);
}

export default AlertMenu;
