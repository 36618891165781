import { useMediaQuery, useTheme } from '@mui/material';

/** @returns {'small' | 'medium' | 'large'} */
export function useScreenSize() {
	const theme = useTheme();
	const matchSmall = useMediaQuery(theme.breakpoints.down('sm'));
	// TODO medium nav

	if (matchSmall) return 'small';
	return 'large';
}

export default useScreenSize;
