import { put, takeLatest, select, call, take } from 'redux-saga/effects';
import {
	actions as assetDetailsActions,
	selectors as assetDetailsSelectors,
} from 'modules/api/asset/detailsModule';
import { actions as availableStorageActions } from 'modules/api/server/availableStorageModule';
import { actions as availableFlavorsActions } from 'modules/api/server/availableFlavorsModule';
import { actions as dynamicChildActions } from 'modules/api/account/limits/dynamicChildModule';
import { actions as parentListActions } from 'modules/api/storm/private/parent/listModule';
import { actions as resizeApiActions } from 'modules/api/server/resizeModule';
import { hasAuthToken as hasAuthTokenSelector } from 'modules/auth/authSelectors';

import {
	selectors as availableConfigsSelectors,
	actions as availableConfigsActions,
} from 'modules/api/server/availableConfigsModule';
import taskSchedulerActions from 'modules/server/taskScheduler/actions';
import snackbarSaga from 'modules/snackbar/sagas';

import {
	selectors as resizeOptionsSelectors,
	actions as resizeOptionsActions,
} from '../options';
import resizeActions from '../actions';

const alsowithStandard = ['categories', 'featureDetails', 'capabilities'];
const cloudExtras = ['privateParent'];
const openStackExtras = ['nocworxRemoteDetails', 'mesDetails'];

const getAlsowith = ({ assetType }) => {
	switch (assetType) {
		case 'open stack':
			return [...alsowithStandard, ...openStackExtras];
		case 'cloud':
			return [...alsowithStandard, ...cloudExtras];
		default:
			return [...alsowithStandard, ...openStackExtras, ...cloudExtras];
	}
};

function* fetchAssetDetails({ uniqId, assetType }) {
	const alsowith = getAlsowith({ assetType });
	yield put(
		assetDetailsActions.fetch({
			uniq_id: uniqId,
			alsowith,
		}),
	);
	// At this point we have everything we need for getting deployOnto default.
	yield take([assetDetailsActions.setType, assetDetailsActions.errorType]);
	yield put(
		resizeOptionsActions.setDeployOnto({
			deployOnto: yield select(assetDetailsSelectors.deployedOnto),
		}),
	);
}

// TODO: The active code is from Open Stack. Use assetType (pass in from component
// via dispatch) and deployOnto (from reducer) to construct logic around this payload.
function* resize({
	uniqId,
	newSize, // todo, put newSize in redux for open stack
	assetType,
	selectedValues,
}) {
	// TODO: have open stack go through the task scheduler modal too?
	if (assetType === 'open stack') {
		yield put(
			resizeApiActions.fetch({
				uniq_id: uniqId,
				newsize: newSize,
			}),
		);
	} else if (assetType === 'cloud') {
		const domain = yield select(assetDetailsSelectors.domain);

		const { deployOnto } = yield select(resizeOptionsSelectors.getStateSlice);
		let apiParams = { uniq_id: uniqId };
		switch (deployOnto) {
			case 'vps':
			case 'cloudDedicated': {
				const configId = yield select(resizeOptionsSelectors.getSelectedValue);
				if (!configId) {
					yield call(snackbarSaga, {
						error: true,
						errorMessage: 'Please select a new size.',
					});
					return;
				}
				apiParams.newsize = configId;
				break;
			}
			default: {
				const parent = (yield select(
					resizeOptionsSelectors.selectedPrivateParent,
				))?.uniq_id;
				const { cpu: vcpu, ram: memory, disk: diskspace } = selectedValues;
				apiParams = {
					...apiParams,
					newsize: 0,
					vcpu,
					memory,
					diskspace,
					parent,
				};
			}
		}
		yield put(
			taskSchedulerActions.openDialog({
				apiParams,
				hostname: domain,
				task: 'resize',
			}),
		);
	}
}

function* initOptions({ assetType, uniqId }) {
	switch (assetType) {
		case 'open stack':
			yield put(availableStorageActions.fetch({ uniq_id: uniqId }));
			yield put(availableFlavorsActions.fetch({ uniq_id: uniqId }));
			break;
		case 'cloud': {
			yield put(taskSchedulerActions.getCurrentTasks(uniqId)); // preload this for TaskScheduler
			yield put(dynamicChildActions.init());
			yield put(
				availableConfigsActions.fetch({
					uniq_id: uniqId,
					filter_product_ready: true,
				}),
			);
			const res = yield take([
				availableConfigsActions.setType,
				availableConfigsActions.errorType,
			]);
			yield call(snackbarSaga, {
				error: res.type === availableConfigsActions.errorType,
				errorMessage: yield select(availableConfigsSelectors.getErrorString),
			});
			break;
		}
		default:
	}
}

// Only cloud needs this list. This is by itself because the call belongs in DeployOnto.
function* initParentList() {
	const isLoggedIn = yield select(hasAuthTokenSelector);
	if (!isLoggedIn) return;

	yield put(parentListActions.init());
}

function* root() {
	yield takeLatest(
		resizeActions.SERVER_RESIZE_ASSET_DETAILS,
		fetchAssetDetails,
	);
	yield takeLatest(
		resizeActions.SERVER_RESIZE_INIT_PARENT_LIST,
		initParentList,
	);
	yield takeLatest(resizeActions.SERVER_RESIZE_RESIZE, resize);
	yield takeLatest(resizeActions.SERVER_RESIZE_INIT_OPTIONS, initOptions);
}

export { fetchAssetDetails, initParentList, initOptions, resize };
export default root;
