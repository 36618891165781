import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';

import Grid from '@material-ui/core/Grid';
import RestoreIcon from '@material-ui/icons/RestoreRounded';

import InfoBox from 'components/molecules/InfoBox';
import LWTypography from 'components/common/LWTypography';
import LWButton from 'components/common/LWButton';

import { selectors as assetDetailsSelectors } from 'modules/api/asset/detailsModule';
import { selectors as productDetailsSelectors } from 'modules/api/product/detailsModule';
import { selectors as backupListSelectors } from 'modules/api/storm/backup/listModule';

import dialogActions from 'modules/dialogs/actions';
import backupsAndImagesActions from 'modules/server/backupsAndImages/list/actions';

import { dateTimeLong } from 'utility/datetime';

dayjs.extend(relativeTime);

const BackupRestoreDialogContent = ({ id, uniqId }) => {
	const dispatch = useDispatch();
	const template = useSelector(assetDetailsSelectors.templateDescription);
	const domain = useSelector(assetDetailsSelectors.domain);
	const selector = backupListSelectors.selectedGenerator(id);
	const { time_taken: timeTaken, size } = useSelector(selector);

	const imagePricing = useSelector(productDetailsSelectors.imagePricing);

	const daysFrom = timeTaken && dayjs(timeTaken).fromNow(true);
	const handleDialog = () => {
		dispatch(dialogActions.close());
		dispatch(
			backupsAndImagesActions.handleDialog({
				type: 'CREATE',
				data: {
					template_description: template,
					source_hostname: domain,
					productPricing: imagePricing.month,
				},
				isOpen: true,
			}),
		);
	};

	const openDialog = () => {
		dispatch(
			dialogActions.open({
				contentKey: 'BackupConfirmationDialogContents',
				contentProps: { id, uniqId },
				dialogProps: {
					maxWidth: 'md',
				},
			}),
		);
	};

	return (
		<>
			<LWTypography BoxProps={{ pb: 4 }}>
				When restoring a server from a backup, previous data on the server will
				be overwritten.
			</LWTypography>
			<Grid container>
				<Grid item xs={12} sm={6}>
					<InfoBox
						details={[dateTimeLong(timeTaken), `${size} GB`, domain, template]}
					/>
				</Grid>
				<Grid
					xs={12}
					sm={6}
					item
					container
					direction="column"
					spacing={2}
					justifyContent="center"
					alignItems="center"
				>
					<Grid item>
						<LWTypography color="palette.text.secondary" variant="h4">
							<RestoreIcon fontSize="large" />
						</LWTypography>
					</Grid>
					<Grid item>Going back {daysFrom}</Grid>
				</Grid>
			</Grid>
			<LWTypography BoxProps={{ pt: 4, pb: 4 }}>
				If unsure about restoration, <b>consider creating an image</b> of the
				server’s current state to have the option of undoing the server
				restoration.
			</LWTypography>
			<Grid container justifyContent="flex-end">
				<LWButton
					color="tertiary"
					onClick={() => dispatch(dialogActions.close())}
					variant="outlined"
				>
					Cancel
				</LWButton>
				<LWButton BoxProps={{ pl: 2 }} onClick={handleDialog}>
					Create Image
				</LWButton>
				<LWButton BoxProps={{ pl: 2 }} onClick={openDialog} variant="contained">
					Continue with Restoration
				</LWButton>
			</Grid>
		</>
	);
};
export default BackupRestoreDialogContent;
