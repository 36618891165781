// @ts-check

/** @type {Record<string, {key: string, label: string, country: string, zone: string, value: string}>} */
const dataCenterMap = {
	1: {
		key: 'lan',
		label: 'Michigan',
		country: 'United States',
		zone: 'US-Central',
		value: '1',
	},
	2: {
		key: 'phx',
		label: 'Arizona',
		country: 'United States',
		zone: 'US-West',
		value: '2',
	},
	3: {
		key: 'ams',
		label: 'Amsterdam',
		country: 'Netherlands',
		zone: 'EU-West',
		value: '3',
	},
	4: {
		key: 'lon',
		label: 'London',
		country: 'United Kingdom',
		zone: 'UK-South-3',
		value: '4',
	},
	5: {
		key: 'ash',
		label: 'Virginia',
		country: 'United States',
		zone: 'US-East',
		value: '5',
	},
	6: {
		key: 'sjc',
		label: 'California',
		country: 'United States',
		zone: 'US-West-2',
		value: '6',
	},
	7: {
		key: 'syd',
		label: 'Sydney',
		country: 'Australia',
		zone: 'OC-South',
		value: '7',
	},
};

export default dataCenterMap;
