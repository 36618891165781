import Stack from '@mui/material/Stack';
import React from 'react';
import useLeftHandLayout from '../context';

/**
 * @param {React.ComponentProps<typeof Stack>} props
 */
export function TopNavTitle(props) {
	const { title } = useLeftHandLayout();

	return (
		<Stack direction="row" useFlexGap gap={100} {...props}>
			{title}
		</Stack>
	);
}

export default TopNavTitle;
