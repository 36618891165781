import React from 'react';
import Link from '@mui/material/Link';
import useLeftHandLayout from '../context';

export const HookedLink = React.forwardRef((props, ref) => {
	const { href, ...linkProps } = props;

	const { linkComponent, linkProp = 'href' } = useLeftHandLayout();

	return (
		<Link
			ref={ref}
			component={linkComponent}
			{...{ [linkProp]: href }}
			{...linkProps}
		/>
	);
});

HookedLink.displayName = 'HookedLink';

export default HookedLink;
