// @ts-check
import { useQuery } from '@tanstack/react-query';
import api from 'modules/queries/api';

/** @param {import('club-sauce/public/billing/cycle.raw').LWApiPublicBillingCycleListResultRawI} data */
const selectMap = (data) => {
	return {
		...data,
		cycles: Object.fromEntries(data.items.map((item) => [item.name, item])),
	};
};

/**
 * @param {import('club-sauce/public/billing/cycle.raw').LWApiPublicBillingCycleListParamsRawI} params
 */
const useBillingCycleList = (params) =>
	useQuery({
		queryKey: ['/billing/cycle/list', params],
		queryFn: () => api.billing.cycle.apiRaw.list(params),
		staleTime: Infinity,
		select: selectMap,
	});

export default useBillingCycleList;
