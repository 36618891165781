import React from 'react';
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';

/**
 * @typedef {import('@mui/material').SxProps<import('@mui/material').Theme>} MuiSxStylesI
 */

/** @type MuiSxStylesI */
const baseHoverStyles = {
	color: (theme) => theme.palette.uc.highlight.text.main,
	bgcolor: (theme) => theme.palette.uc.highlight.bg.weak,
};

/** @type MuiSxStylesI */
const baseStyles = {
	color: (theme) => theme.palette.text.primary,
	':hover': baseHoverStyles,
	':active': baseHoverStyles,
	':focus-visible': {
		outlineOffset: '2px',
		outlineWidth: '2px',
		outlineStyle: 'solid',
		outlineColor: (theme) => theme.palette.uc.highlight.border.main,
	},
	gap: (theme) => theme.spacing(150),
	padding: (theme) => `${theme.spacing(200)}!important`,
};

/**
 * @typedef {React.ComponentProps<typeof MenuItem>} CreateItemPropsI
 * @property {React.Node?} icon
 * @property {React.Node?} endIcon
 * @property {string?} description
 * @property {React.Node} children
 */

/**
 * @param {CreateItemPropsI} props
 */
export function CreateItem(props) {
	const { icon, endIcon, description, children, ...menuItemProps } = props;

	/** @type MuiSxStylesI */
	const sxStyles = { ...baseStyles };

	return (
		<MenuItem sx={sxStyles} {...menuItemProps}>
			{icon && <ListItemIcon>{icon}</ListItemIcon>}
			<ListItemText primary={children} secondary={description} />
			{endIcon && (
				<ListItemIcon sx={{ justifyContent: 'flex-end' }}>
					{endIcon}
				</ListItemIcon>
			)}
		</MenuItem>
	);
}
export default CreateItem;
