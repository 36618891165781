import round from 'lodash/round';
import attributionActions from 'modules/attribution/actions';
import { defaultData, defaultParams } from 'modules/gtm/defaults';
import { actions as basketActions } from 'modules/basket';
import { cartItemAddActions } from 'modules/api/market/cart/item/addModule';
import {
	getDueTodayHelper,
	findItemTotals,
} from 'modules/api/market/cart/detailsModule';
import cartActions from 'modules/cart/actions';
import analyticsActions from '../actions';

/**
 * @param {MarketCartItemTotal} itemTotals
 */
const selectPrice = (itemTotals) => Number(round(Number(itemTotals?.price), 2));

/**
 * @param {MarketCartItemTotal} itemTotals
 */
const selectValue = (itemTotals) => Number(round(Number(itemTotals?.cost), 2));

const basketBeaconActions = {
	basket_purchase: 'basket_purchase',
};

const brand = 'Liquid Web';

const baskets = {
	[basketActions.BASKET_CREATED]: () => {
		return [
			{ ...defaultParams, data: { ...defaultData } },
			{
				event: 'lw.ecommerce',
				data: {
					category: 'Ecommerce',
					action: 'basket_created',
				},
			},
		];
	},
	[cartItemAddActions.setType]: ({ payload }) => {
		const { items } = payload;
		// the item just added is the last one in the array
		const itemAdded = items[items.length - 1];
		const { code: productCode } = itemAdded;
		const totals = findItemTotals(payload.totals, itemAdded.uuid);

		const price = selectPrice(totals);
		const value = selectValue(totals);

		return [
			{ ...defaultParams, data: { ...defaultData } },
			{
				event: 'lw.ecommerce',
				ecommerceType: 'ua', // for UA events
				data: {
					category: 'Ecommerce',
					action: 'basketItem_added',
				},
				ecommerce: {
					add: {
						products: [
							{
								name: productCode,
								price,
								quantity: 1,
								brand,
							},
						],
					},
				},
			},
			{ ...defaultParams, data: { ...defaultData } },
			{
				event: 'lw.ecommerce',
				ecommerceType: 'ga4', // for GA4 events
				data: {
					category: 'Ecommerce',
					action: 'basketItem_added',
				},
				ecommerceEventName: 'add_to_cart', // for GA4 only
				ecommerce: {
					currency: 'USD',
					value,
					items: [
						{
							item_name: productCode,
							price,
							quantity: 1,
							item_brand: brand,
						},
					],
				},
				cart: {
					cart_uuid: payload.uuid,
					total: payload.totals?.summary?.cost,
					items: items.map((item) => ({
						category: item.type,
						cycle: item.cycle,
						id: item.uuid,
						name: item.details.title,
						price: selectValue(findItemTotals(payload.totals, item.uuid)),
						quantity: item.units,
					})),
					currency: 'USD',
				},
			},
		];
	},
	/**
	 * @param {object} param0
	 * @param {MarketCartItem} param0.item
	 * @param {number} param0.units
	 * @param {MarketCartItemTotal} param0.itemTotals
	 * @param {MarketCartDetailsNativeData} param0.cart
	 */
	[analyticsActions.ANALYTICS_EVENT_CART_ITEM_UNITS_ADDED]: ({
		item,
		units,
		itemTotals,
		cart,
	}) => {
		const { code: productCode } = item;
		const price = selectPrice(itemTotals);
		const value = selectValue(itemTotals);

		return [
			{ ...defaultParams, data: { ...defaultData } },
			{
				event: 'lw.ecommerce',
				ecommerceType: 'ga4', // for GA4 events
				data: {
					category: 'Ecommerce',
					action: 'basketItem_added',
				},
				ecommerceEventName: 'add_to_cart', // for GA4 only
				ecommerce: {
					currency: 'USD',
					value,
					items: [
						{
							item_name: productCode,
							price,
							quantity: units,
							item_brand: brand,
						},
					],
				},
				cart: {
					cart_uuid: cart.uuid,
					total: itemTotals.cost,
					items: cart.items.map((cartItem) => ({
						category: cartItem.type,
						cycle: cartItem.cycle,
						id: cartItem.uuid,
						name: cartItem.details.title,
						price: selectValue(findItemTotals(cart.totals, cartItem.uuid)),
						quantity: cartItem.units,
					})),
					currency: 'USD',
				},
			},
		];
	},
	/**
	 * @param {object} param0
	 * @param {MarketCartItem} param0.item
	 * @param {number} param0.units
	 * @param {MarketCartItemTotal} param0.itemTotals
	 * @param {MarketCartDetailsNativeData} param0.cart
	 */
	[analyticsActions.ANALYTICS_EVENT_CART_ITEM_UNITS_REMOVED]: ({
		item,
		units,
		itemTotals,
		cart,
	}) => {
		const { code: productCode } = item;
		const price = selectPrice(itemTotals);
		const value = selectValue(itemTotals);

		return [
			{ ...defaultParams, data: { ...defaultData } },
			{
				event: 'lw.ecommerce',
				ecommerceType: 'ga4', // for GA4 events
				data: {
					category: 'Ecommerce',
					action: 'basketItem_removed',
				},
				ecommerceEventName: 'remove_from_cart', // for GA4 only
				ecommerce: {
					currency: 'USD',
					value,
					items: [
						{
							item_name: productCode,
							price,
							quantity: units,
							item_brand: brand,
						},
					],
				},
				cart: {
					cart_uuid: cart.uuid,
					total: itemTotals.cost,
					items: cart.items.map((cartItem) => ({
						category: cartItem.type,
						cycle: cartItem.cycle,
						id: cartItem.uuid,
						name: cartItem.details.title,
						price: selectValue(findItemTotals(cart.totals, cartItem.uuid)),
						quantity: cartItem.units,
					})),
					currency: 'USD',
				},
			},
		];
	},
	/**
	 * @param {object} param0
	 * @param {MarketCartItem[]} param0.items
	 * @param {number} param0.value
	 * @param {MarketCartDetailsNativeData['totals']} param0.totals
	 */
	[analyticsActions.ANALYTICS_EVENT_CHECKOUT_BEGIN]: ({
		items,
		value,
		totals,
	}) => {
		return [
			{ ...defaultParams, data: { ...defaultData } },
			{
				event: 'lw.ecommerce',
				ecommerceType: 'ga4', // for GA4 events
				ecommerceEventName: 'begin_checkout', // for GA4 only
				ecommerce: {
					currency: 'USD',
					value,
					items: items.map((item) => {
						const itemTotals = findItemTotals(totals, item.uuid);
						const price = selectPrice(itemTotals);
						return {
							item_name: item.code,
							item_id: item.uuid,
							price,
							item_brand: brand,
							quantity: item.units,
						};
					}),
				},
			},
		];
	},
	/**
	 * @param {object} param0
	 * @param {MarketCartItem} param0.item
	 * @param {MarketCartItemTotal} param0.itemTotals
	 * */
	[cartActions.CART_ITEM_REMOVED]: ({ item, itemTotals }) => {
		const { code: productCode } = item;
		const price = selectPrice(itemTotals);
		const value = selectValue(itemTotals);

		return [
			{ ...defaultParams, data: { ...defaultData } },
			{
				event: 'lw.ecommerce',
				ecommerceType: 'ua', // for UA events
				data: {
					category: 'Ecommerce',
					action: 'basketItem_removed',
				},
				ecommerce: {
					remove: {
						products: [
							{
								name: productCode,
								price,
								quantity: 1,
								brand,
							},
						],
					},
				},
			},
			{ ...defaultParams, data: { ...defaultData } },
			{
				event: 'lw.ecommerce',
				ecommerceType: 'ga4', // for GA4 events
				data: {
					category: 'Ecommerce',
					action: 'basketItem_removed',
				},
				ecommerceEventName: 'remove_from_cart', // for GA4 only
				ecommerce: {
					currency: 'USD',
					value,
					items: [
						{
							item_name: productCode,
							price,
							quantity: 1,
							item_brand: brand,
						},
					],
				},
			},
		];
	},
	[attributionActions.BASKET_PURCHASE_SEND_ATTRIBUTION_DATA]: ({
		payload: { attributionData, basketDetails },
	}) => {
		const uaItems = [];
		const gaItems = [];

		basketDetails.items.forEach(({ code, uuid, units }) => {
			const totals = findItemTotals(basketDetails.totals, uuid);

			const price = selectPrice(totals);

			uaItems.push({
				name: code,
				price,
				quantity: units || 1,
				brand,
			});

			gaItems.push({
				item_name: code,
				price,
				quantity: units || 1,
				item_brand: brand,
			});
		});

		return [
			{ ...defaultParams, data: { ...defaultData } },
			{
				event: 'lw.ecommerce',
				ecommerceType: 'ua', // for UA events
				data: {
					category: 'Ecommerce',
					action: basketBeaconActions.basket_purchase,
					...attributionData,
				},
				ecommerce: {
					purchased: {
						products: uaItems,
					},
				},
			},
			{ ...defaultParams, data: { ...defaultData } },
			{
				event: 'lw.ecommerce',
				ecommerceType: 'ga4', // for GA4 events
				ecommerceEventName: 'purchase', // for GA4 only
				data: {
					category: 'Ecommerce',
					action: basketBeaconActions.basket_purchase,
					...attributionData,
				},
				ecommerce: {
					currency: 'USD',
					transaction_id: basketDetails?.metadata.order_id,
					value: round(Number(getDueTodayHelper(basketDetails?.totals)), 2),
					items: gaItems,
				},
			},
		];
	},
};

export { basketBeaconActions };
export default baskets;
