import React from 'react';
import PropTypes from 'prop-types';
import StatusLed from 'components/atoms/StatusLed';
import StatusSpinner from 'components/atoms/StatusSpinner';

export const statusColors = {
	active: {
		primary: 'palette.common.blue12',
		secondary: 'palette.common.blue12',
	},
	disabled: {
		primary: 'palette.grey.disabled',
		secondary: 'palette.grey.disabled',
	},
	normal: {
		primary: 'palette.common.green6',
		secondary: 'palette.common.green7',
	},
	pending: {
		primary: 'palette.common.blue5',
		secondary: 'palette.common.blue6',
	},
	mildWarning: {
		primary: 'palette.common.yellow3',
		secondary: 'palette.common.yellow3',
	},
	warning: {
		primary: 'palette.common.orange2',
		secondary: 'palette.common.orange2',
	},
	high: {
		primary: 'palette.error.main',
		secondary: 'palette.common.red2',
	},
};

const Icon = ({ level, ledProps }) =>
	level === 'pending' ? (
		<StatusSpinner
			color={statusColors[level].primary}
			backgroundColor={statusColors[level].secondary}
			centerMatchBackground={false}
		/>
	) : (
		<StatusLed color={statusColors[level].primary} {...ledProps} />
	);

Icon.propTypes = {
	level: PropTypes.oneOf(Object.keys(statusColors)),
};

export default Icon;
