import * as React from 'react';
import { useEffect, useState } from 'react';

import { useHistory } from 'react-router';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';

import Box from '@material-ui/core/Box';
import Skeleton from '@material-ui/lab/Skeleton';

import CopyButton from 'components/molecules/CopyButton';
import LWIconButton from 'components/atoms/LWIconButton';
import LWLink from 'components/common/LWLink';
import LWTypography from 'components/common/LWTypography';

import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import VisibilityIcon from '@material-ui/icons/Visibility';

import dialogActions from 'modules/dialogs/actions';
import useAssetPassword from 'modules/queries/asset/usePassword';

const SCredentialValue = styled(LWTypography)`
	font-family: 'PT Mono';
	word-break: break-word;
	max-width: 360px;
`;

const CredentialsDialogContents = ({ uniqId, ip }) => {
	const [isVisible, setIsVisible] = useState(false);

	const {
		isLoading,
		data,
		remove: removeQueryData,
	} = useAssetPassword({ uniq_id: uniqId }, { enabled: isVisible });

	const dispatch = useDispatch();
	const history = useHistory();

	useEffect(() => {
		// Clear query storage upon unmount. Ensures each use
		// of this feature causes an API request that is logged
		// by the server.
		return () => removeQueryData();
	}, [removeQueryData]);

	const navigateToSupportAccess = () => {
		dispatch(dialogActions.close());
		history.push(`${uniqId}/support-access`);
	};

	return (
		<>
			<Box pb={2} borderBottom={1} borderColor="divider">
				<LWTypography>
					These credentials reflect the most recent username and password{' '}
					<strong>in our records</strong>. If you&apos;ve changed them since
					they were originally set, they may no longer be accurate.
				</LWTypography>
			</Box>
			<Box py={2} borderBottom={1} borderColor="divider">
				<Box display="flex" justifyContent="space-between" alignItems="center">
					<LWTypography>Server Access Details</LWTypography>
					<LWIconButton
						$variant="plain"
						onClick={() => setIsVisible((prev) => !prev)}
					>
						{isVisible ? <VisibilityIcon /> : <VisibilityOffIcon />}
					</LWIconButton>
				</Box>
				{isVisible && (
					<Box
						display="grid"
						gridTemplateColumns={isLoading ? 'auto 1fr' : 'auto auto 1fr'}
						gridColumnGap={16}
						gridRowGap={8}
						alignItems="center"
						my={2}
					>
						<LWTypography bold>IP Address</LWTypography>
						{isLoading ? (
							<Skeleton />
						) : (
							<>
								<CopyButton text={ip} />
								<SCredentialValue>{ip}</SCredentialValue>
							</>
						)}
						<LWTypography bold>Username</LWTypography>
						{isLoading ? (
							<Skeleton />
						) : (
							<>
								<CopyButton disabled={!data?.username} text={data?.username} />
								<SCredentialValue>
									{data?.username ?? '(unknown)'}
								</SCredentialValue>
							</>
						)}
						<LWTypography bold>Password</LWTypography>
						{isLoading ? (
							<Skeleton />
						) : (
							<>
								<CopyButton disabled={!data?.password} text={data?.password} />
								<SCredentialValue>
									{data?.password ?? '(unknown)'}
								</SCredentialValue>
							</>
						)}
					</Box>
				)}
			</Box>
			<Box pt={2}>
				<LWTypography>
					Are these out of date?{' '}
					<LWLink onClick={navigateToSupportAccess}>
						Update our records with your most current credentials
					</LWLink>
				</LWTypography>
			</Box>
		</>
	);
};

export default CredentialsDialogContents;
