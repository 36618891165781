import React, { useEffect, useState } from 'react';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import EllipsisIcon from 'undercurrent/icons/lucide/Ellipsis';
import HookedLink from '../common/HookedLink';
import useLeftHandLayout from '../context';

/**
 * @param {React.ComponentProps<typeof IconButton>} props
 */
export function LeftNavFooterMenu(props) {
	const [menuElement, setMenuElement] = useState();
	const open = menuElement !== undefined;

	const { appPath } = useLeftHandLayout();

	// Close menu when appPath changes
	useEffect(() => {
		if (appPath === undefined) return;
		setMenuElement(undefined);
	}, [appPath]);

	return (
		<>
			<Tooltip title="Billing">
				<IconButton
					onClick={({ currentTarget }) => setMenuElement(currentTarget)}
					{...props}
				>
					<EllipsisIcon fontSize="small" />
				</IconButton>
			</Tooltip>
			<Menu open={open} anchorEl={menuElement} onClose={() => setMenuElement()}>
				<HookedLink
					role="menuitem"
					href="/account/billing/pay"
					sx={{ textDecoration: 'none' }}
				>
					<MenuItem role="none">Pay now</MenuItem>
				</HookedLink>
				<HookedLink
					role="menuitem"
					href="/account/billing"
					sx={{ textDecoration: 'none' }}
				>
					<MenuItem role="none">More details</MenuItem>
				</HookedLink>
			</Menu>
		</>
	);
}

export default LeftNavFooterMenu;
