const actions = {
	INITIALIZED: 'GTM_INITIALIZED',
	initialized: () => ({
		type: actions.INITIALIZED,
	}),
	ANALYTICS_EVENT: 'ANALYTICS_EVENT',
	sendAnalyticsEvent: ({
		category,
		action,
		label,
		value,
		nonInteraction = false,
	}) => ({
		type: actions.ANALYTICS_EVENT,
		payload: {
			category,
			action,
			label,
			value,
			nonInteraction,
		},
	}),
	ANALYTICS_EVENT_CART_ITEM_UNITS_ADDED:
		'ANALYTICS_EVENT_CART_ITEM_UNITS_ADDED:',
	/**
	 * @param {object} param0
	 * @param {MarketCartItem} param0.item
	 * @param {number} param0.units
	 * @param {MarketCartItemTotal} param0.itemTotals
	 * @param {MarketCartDetailsNativeData} param0.cart
	 */
	sendAnalyticsCartItemUnitsAdded: ({ item, units, itemTotals, cart }) => ({
		type: actions.ANALYTICS_EVENT_CART_ITEM_UNITS_ADDED,
		item,
		units,
		itemTotals,
		cart,
	}),
	ANALYTICS_EVENT_CART_ITEM_UNITS_REMOVED:
		'ANALYTICS_EVENT_CART_ITEM_UNITS_REMOVED:',
	/**
	 * @param {object} param0
	 * @param {MarketCartItem} param0.item
	 * @param {number} param0.units
	 * @param {MarketCartItemTotal} param0.itemTotals
	 * @param {MarketCartDetailsNativeData} param0.cart
	 */
	sendAnalyticsCartItemUnitsRemoved: ({ item, units, itemTotals, cart }) => ({
		type: actions.ANALYTICS_EVENT_CART_ITEM_UNITS_REMOVED,
		item,
		units,
		itemTotals,
		cart,
	}),
	ANALYTICS_EVENT_CHECKOUT_BEGIN: 'ANALYTICS_EVENT_CHECKOUT_BEGIN',
	sendAnalyticsCheckoutBegin: ({ value, items, totals }) => ({
		type: actions.ANALYTICS_EVENT_CHECKOUT_BEGIN,
		items,
		value,
		totals,
	}),
};

export default actions;
