import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';
import { call, takeLatest, put, take, select } from 'redux-saga/effects';
import { push } from 'connected-react-router';
import snackbarSagas from 'modules/snackbar/sagas';
import { POWER_ACTION, POWER_STATUS } from 'utility/powerStatus';
import { actions as usertaskListActions } from 'modules/api/usertask/listModule';
import { removeUndefinedKeys } from 'utility/tools/objects';
import targetPowerActions from 'modules/server/targetPower/actions';
import taskSchedulerActions from './actions';
import taskSchedulerSelectors from './selectors';
import supportedTasks from './supportedTasks';

dayjs.extend(duration);

function* scheduleTask({ payload: { deferSeconds, params, task } }) {
	const force = yield select(taskSchedulerSelectors.getForceReboot);

	const modifiedTask = force && task === 'reboot' ? 'reset' : task;

	const { action, selectors } = supportedTasks[modifiedTask];

	yield put(
		action.fetchRaw({
			data: { params },
			headers: removeUndefinedKeys({
				// removes this if undefined.
				'X-Meta-Timing-Defer': deferSeconds || undefined,
			}),
		}),
	);

	const result = yield take([action.setType, action.errorType]);

	const error = result?.type === action.errorType;
	yield call(snackbarSagas, {
		error,
		errorMessage: yield select(selectors.getErrorString),
	});
	if (error) return;

	if (['reboot', 'reset'].includes(modifiedTask) && !deferSeconds) {
		yield put(
			targetPowerActions.setServerTargetPower({
				uniqId: params.uniq_id,
				action: POWER_ACTION.REBOOT,
				status: POWER_STATUS.ON,
			}),
		);
		yield call(snackbarSagas, {
			successMessage: `Server ${modifiedTask} started`,
		});
	}
	if (['reboot', 'reset'].includes(modifiedTask) && deferSeconds) {
		yield call(snackbarSagas, {
			successMessage: `Server ${modifiedTask} scheduled`,
		});
	}

	const callback = yield select(taskSchedulerSelectors.getCallback);

	/*
	 * TODO DEV-3833
	 * This logic is a little wonky. The reason not to call the callback directly is
	 * that if the deferSeconds are set, we want a redirect to the scheduled tasks page.
	 * If there is no callback and deferSeconds are blank then we want to set the redirectPath.
	 * This was done to keep in previous behavior on non bare-metal servers.
	 * When refactring, we should consider just invalidating caches which is what is called in the callback
	 * and eliminate the need to do any redirections, except maybe for the scheduled task page.
	 */
	if (deferSeconds) {
		yield put(push('/servers/scheduled-tasks'));
	} else if (callback) {
		callback();
	} else {
		yield put(push(supportedTasks[modifiedTask].redirectPath(params)));
	}

	yield put(taskSchedulerActions.closeDialog());
}

function* getCurrentTasks({ uniqId }) {
	yield put(
		usertaskListActions.fetch({
			uniq_id: uniqId,
			alsowith: ['action'],
			status: 'pending',
		}),
	);
}

export { scheduleTask, getCurrentTasks };

export default function* taskSchedulerSagas() {
	yield takeLatest(taskSchedulerActions.SCHEDULE, scheduleTask);
	yield takeLatest(taskSchedulerActions.GET_CURRENT_TASKS, getCurrentTasks);
}
