import { SvgIcon, Typography } from '@mui/material';
import React from 'react';

/**
 * @typedef {import('@mui/material').SxProps<import('@mui/material').Theme>} MuiSxStylesI
 */

/** @type MuiSxStylesI */
const baseHoverStyles = {
	bgcolor: (theme) => theme.palette.uc.bg.medium,
	outline: 'none',
};

/** @type MuiSxStylesI */
const baseStyles = {
	height: (theme) => theme.size.md,
	overflow: 'hidden',
	display: 'flex',
	alignItems: 'stretch',
	justifyContent: 'center',
	color: (theme) => theme.palette.text.primary,
	textDecoration: 'none',
	paddingY: 100,
	paddingX: 150,
	borderRadius: (theme) => theme.borderRadius.roundedSm,
	gap: 100,
	':hover': baseHoverStyles,
	':active': baseHoverStyles,
	':focus-visible': {
		outlineOffset: '2px',
		outlineWidth: '2px',
		outlineStyle: 'solid',
		outlineColor: (theme) => theme.palette.uc.highlight.border.main,
	},
};

/** @type MuiSxStylesI */
const activeStyles = baseStyles[':hover'];

/**
 * @typedef NavLinkPropsI
 * @property {boolean} active
 * @property {React.Node} children
 */

/**
 * @param {NavLinkPropsI} props
 */
export function NavLink(props) {
	const {
		component = 'a',
		active = false,
		startIcon,
		endIcon,
		sx: sxProp,
		children,
		...anchorProps
	} = props;

	/** @type MuiSxStylesI */
	const sxStyles = { ...baseStyles };
	if (active) Object.assign(sxStyles, activeStyles);

	return (
		<Typography
			component={component}
			sx={{ ...sxStyles, ...sxProp }}
			{...anchorProps}
		>
			{startIcon && <SvgIcon fontSize="small">{startIcon}</SvgIcon>}
			<Typography
				component="span"
				variant="body-sm"
				display="block"
				flex="0% 1 1"
				whiteSpace="nowrap"
			>
				{children}
			</Typography>
			{endIcon && <SvgIcon fontSize="small">{endIcon}</SvgIcon>}
		</Typography>
	);
}
export default NavLink;
