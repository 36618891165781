// @ts-check
import React, { createContext, useContext, useMemo } from 'react';
import inputHasError from './inputHasError';

/** @typedef {string} FormValue */
/** @typedef {Record<string, FormValue>} FormValues */
/** @typedef {Record<string, string>} FormErrors */
/** @typedef {(value: FormValue, formValues?: FormValues) => string} Validator */

/**
 * @typedef FormContextValueI
 * @property {FormErrors} errors
 * @property {import('./inputHasError').SkipValidations} [skipValidations]
 * @property {boolean} isValid
 * @property {(errors: FormErrors) => void} onErrorsChange
 * @property {(values: FormValues) => void} onValuesChange
 * @property {Record<string, Validator>} validators
 * @property {FormValues} values
 * */

/** @type {React.Context<FormContextValueI>} */
const formContext = createContext({
	errors: {},
	/** @type {boolean} */
	isValid: false,
	// eslint-disable-next-line no-unused-vars
	onErrorsChange: (errors) => {},
	// eslint-disable-next-line no-unused-vars
	onValuesChange: (values) => {},
	validators: {},
	values: {},
});

export const useForm = () => useContext(formContext);

/**
 * @param {string} inputName
 */
export const useInput = (inputName) => {
	const {
		values,
		onValuesChange,
		errors,
		onErrorsChange,
		validators,
		skipValidations,
	} = useForm();

	/** @param {string} error */
	const onErrorChange = (error) => {
		onErrorsChange({ ...errors, [inputName]: error });
	};

	/** @param {FormValue} value */
	const onValueChange = (value) => {
		onValuesChange({ ...values, [inputName]: value });
	};

	const error = errors[inputName];
	const value = values[inputName];

	const validator = validators?.[inputName];

	/**
	 * Checks for native html error and custom error. Calls error callback with
	 * error string (or empty string, if no error) as param.
	 * @param {HTMLInputElement} inputElement */
	const validate = (inputElement) => {
		const nativeError = inputHasError(inputElement, skipValidations);
		const customError = validator ? validator(inputElement.value, values) : '';

		const newError = nativeError || customError;
		onErrorChange(newError);
	};

	/**
	 * Just checks for isRequired and any custom validation
	 * @param {string} selectValue
	 * @param {boolean} isRequired */
	const validateSimple = (selectValue, isRequired) => {
		const requiredError =
			isRequired &&
			(selectValue === '' || selectValue === null || selectValue === undefined)
				? 'Selecting an option is required.'
				: '';
		const customError = validator ? validator(selectValue, values) : '';

		const newError = requiredError || customError;
		onErrorChange(newError);
	};

	return {
		error,
		onValueChange,
		validate,
		validateSimple,
		value,
	};
};

/** @typedef {React.DetailedHTMLProps<React.FormHTMLAttributes<HTMLFormElement>, HTMLFormElement>} HTMLFormAttributes */

/**
 * @typedef FormPropsI
 * @property {React.ReactNode} children
 * @property {FormErrors} [errors] keys should match `name` props of input components
 * @property {import('./inputHasError').SkipValidations} [skipValidations] set validity key to `true` to ignore; sometimes you want the behavior of certain input attributes but want to ignore violating the validity - for example, you may want the behavior of a granular `step` attribute but still want to allow inputs with values that violate the `step`
 * @property {(values: FormValues) => void} onSubmit
 * @property {(errors: FormErrors) => void} [onErrorsChange]
 * @property {(values: FormValues) => void} onValuesChange
 * @property {Record<string, Validator>} [validators]
 * @property {FormValues} values keys should match `name` props of input components
 */

/**
 * A form context that allows for consistent valiating while interacting with
 * the form. Validates prior to submit with native browser validation (see
 * below). Additionally, allows for custom validation. The same validation can
 * be accessed and used in child components' events with the `useInput` hook.
 * Form validity can be accesed with the `useForm` hook.
 * @see https://developer.mozilla.org/en-US/docs/Web/API/HTMLObjectElement/validationMessage
 * @see https://developer.mozilla.org/en-US/docs/Web/API/HTMLObjectElement/validity
 * @param {Omit<HTMLFormAttributes, 'onSubmit'> & FormPropsI} props
 * @example
 * const MyForm = ({ onSubmit }) => {
 *  	const [formValues, setFormValues] = useState({ firstName: '' });
 *  	const [formErrors, setFormErrors] = useState({ firstName: '' });
 *
 *  	return (
 *  		<LWForm
 *  			values={formValues}
 *  			onValuesChange={setFormValues}
 *  			errors={formErrors}
 *  			onErrorsChange={setFormErrors}
 *  			onSubmit={(values) => {
 *  				onSubmit(values)
 *  			}}
 *  		>
 *  			<HookedTextField name="firstName" label="First Name" required />
 *  			<HookedSubmit>Submit</HookedSubmit>
 *  		</LWForm>
 *  	);
 *  };
 * }
 */
const LWForm = ({
	children,
	errors = {},
	skipValidations,
	noValidate = true,
	onErrorsChange = () => {},
	onSubmit,
	onValuesChange,
	validators = {},
	values,
	...rest
}) => {
	/** @param {React.FormEvent<HTMLFormElement>} event */
	const handleSubmit = (event) => {
		event.preventDefault();

		/** @type {FormErrors} */
		const newErrors = {};

		// @ts-ignore
		event.target.querySelectorAll('input, textarea').forEach((inputEl) => {
			const validator = validators[inputEl.name];

			const nativeError = inputHasError(inputEl, skipValidations);
			const customError = validator ? validator(inputEl.value, values) : '';

			const error = nativeError || customError;

			if (!error) {
				return;
			}

			// focus the first input that has an error
			if (Object.values(newErrors).every((err) => !err)) {
				inputEl.focus();
			}

			newErrors[inputEl.name] = error;
		});

		onErrorsChange(newErrors);

		if (Object.values(newErrors).some((err) => Boolean(err))) {
			return;
		}

		onSubmit(values);
	};

	const isValid = useMemo(
		() => Object.values(errors).every((err) => !err),
		[errors],
	);

	return (
		// @ts-ignore
		<formContext.Provider
			value={{
				errors,
				skipValidations,
				isValid,
				onErrorsChange,
				onValuesChange,
				validators,
				values,
			}}
		>
			<form onSubmit={handleSubmit} noValidate={noValidate} {...rest}>
				{children}
			</form>
		</formContext.Provider>
	);
};

export default LWForm;
